import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class PromoServices extends Component {

  static get(query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/promo-codes${query}`).then(callBack)
  }

  static add(data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/promo-codes`, data).then(callBack)
  }

}

import React, {Component} from 'react'
import { OrdersServices } from './../../../services'
import { Spinner } from './../../../components'
import StarRatings from 'react-star-ratings'
import State from './State'
import Invoice from './Invoice'
import Details from './Details'
import Rooms from './Rooms'
import Tasks from './Tasks'
import Customer from './Customer'
import Fixer from './Fixer'
import Quotation from './Quotation'
import Actions from './Actions'
import Report from './Report'



export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  openMap(){
    let url = `http://maps.google.com/maps?q=loc:${parseFloat(this.props.order.address.latitude)},${parseFloat(this.props.order.address.longitude)}`
    window.open(url, "_blank")
  }

  pay(){
    this.setState({loading:true})
    let invoice = global.order.invoice
    if (!invoice) OrdersServices.getInvoice(global.order.id, res=> this.callPay(res.data) )
    else this.callPay(invoice)
  }

  callPay(invoice){
    let data = {
      method: 'cash',
      amount: invoice.collectedAmount
    }
    OrdersServices.pay(global.order.id, data, res => this.setState({loading:false},()=>this.props.update()) )
  }

  reOpen(){
    this.setState({loading:true})
    OrdersServices.reOpen(global.order.id, res => {
      console.log(res);
      this.setState({loading:false},()=>this.props.update())
    })
  }

  render(){
    return(
      <div className='space-btw invoice' style={{width:'100%'}}>

        <div style={{width:'100%', height:window.innerHeight-55, overflow:'scroll'}}>

          <Details />

          {[3,4].indexOf(global.order.service.id) > -1 ? <Tasks /> : <Rooms />}


          <Customer />

          {!global.order.fixer?null: <Fixer />}

          {!global.order.quotation?null: <Quotation update={()=>this.props.update()}/>}

          {[3,4].includes(global.order.service.id) &&
            ['Completed','Done'].includes(global.order.state) && <Report />}

          <Actions update={()=>this.props.update()}/>

        </div>


        <div className='right-hand'>

          <State/>
          <div className='invoice' style={{marginTop:'auto'}}>
            {global.order.invoice && (
              <>
              <span className='parent'>Invoice #{global.order.invoice.id}</span>
              <Invoice forReprice/>
            </>
          )}
          {
            global.order.state == 'Done' &&
            <div className='re-price' onClick={()=> this.pay()}>
              <span>Confirm payment</span>
            </div>
          }
          {
            ['Completed','Canceled'].includes(global.order.state) &&
            <div className='re-price' onClick={()=> this.reOpen()}>
              <span>Re-Open</span>
            </div>
          }
        </div>
      </div>
      <Spinner loading={this.state.loading} />
    </div>
  )
}
}

import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class Services extends Component {

  static getCities(callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/cities`).then(callBack)
  }

  static getAreas(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/cities/${id}/areas`).then(callBack)
  }

  static getUser(callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/profile`).then(callBack)
  }

  static logout(callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/logout`).then(callBack)
  }

  static getBalance(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/users/${id}/balance`).then(callBack)
  }

  static addNote(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/users/${id}/notes`, data).then(callBack)
  }

  static notify(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/users/${id}/notify`, data).then(callBack)
  }

  static getServices(callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/services`).then(callBack)
  }

  static getReport(query, type, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/reports/${type}${query}`).then(callBack)
  }

  static getStatus(statusFor, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/states/${statusFor}`).then(callBack)
  }

  static getNotes(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/users/${id}/notes`).then(callBack)
  }


}

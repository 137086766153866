import React , { useState, useEffect } from 'react'
import { Spinner } from './../../components'
import { Services } from './../../services'
import { Table, } from 'reactstrap'


export default function Notes({ user, close }) {

  const [notes, setNotes] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(()=> getNotes(), [])

  const getNotes = () => {
    Services.getNotes(user.id, res=> setNotes(res.data))
    setLoading(false)
  }

  return(
    <div className=''>
      <Table>
        <thead>
          <tr>
            <th className='col-title'>Maker</th>
            <th className='col-title'>Note</th>
            <th className='col-title' width='20%'>Date</th>
          </tr>
        </thead>
        <tbody>
          {notes&&notes.map(e=>(
            <tr key={e.id}>
              <th className='col-value'>{e.actionMaker.name}</th>
              <th className='col-value'>{e.briefDescription}</th>
              <th className='col-value'>{e.createdAt}</th>
            </tr>
          ))}
        </tbody>
      </Table>

      <Spinner loading={loading}/>
    </div>
  )
}

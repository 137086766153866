import React, { Component } from 'react';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Logo } from './../../assets'
import { G } from './../../core/Global'
import { AdminServices } from './../../services'
import { Spinner, FModal, AssignRoles, Filter } from './../../components'
import Add from './Add'

let page = 1

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      admins:[],
      noPromo:false,
      pages:'',
      query:'',
    }
  }

  componentDidMount(){
    page = 1
    this.get(page)
  }
  componentWillUnmount(){
  }

  get(page){
    this.setState({loading:true})
    AdminServices.get(page, this.state.query, (res)=>{
      this.setState({loading:false, admins:res.data, noPromo:res.data.length==0})
    })
  }

  goAdd(){
    this.props.history.push(`admins/add`)
  }

  setQuery(query){
    this.setState({query:query.replace('?','&')}, ()=> this.get(1))
  }


  render() {
    return (
      <div className="flex-row main-items promo">
        <div className="items">
          <div className='space-btw title'>
            <h1>Admins</h1>
            <div className='f-btn btn-add' onClick={()=>this.setState({addPopup:true})}>
              <span>Add Admin</span>
            </div>
          </div>
          <Filter bySearchTerm setQuery={(q)=>this.setQuery(q)} />
          {this.state.loading?null:
            !this.state.noPromo?(
              <Table>
                <thead>
                  <tr>
                    <th className='col-title first'>name</th>
                    <th className='col-title'>phone</th>
                    <th className='col-title'>register data</th>
                    <th className='col-title'>roles</th>
                    <th className='col-title' width='10%'></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.admins.map((e,k)=>(
                    <tr key={k} >
                      <th className='col-value first'>{e.enName}</th>
                      <th className='col-value'>{e.phone}</th>
                      <th className='col-value'>{e.createdAt}</th>
                      <th className='col-value'>
                        {e.roles.map((i,k)=>(
                          <span key={k}>{i.name}, </span>
                        ))}
                      </th>
                      <th className='col-value'>
                          {/* <i className='mdi mdi-delete'/> */}
                          <i className='mdi mdi-pencil' onClick={()=>this.setState({assignRolePopup:true,admin:e})}/>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </Table>
          ):(
            <div className='no-data'>
              <p>😕</p>
              <span>There is no admins</span>
            </div>
          )}

          <Spinner loading={this.state.loading} />
        </div>

        <FModal
          title='Add Admin'
          state={this.state.addPopup}
          content={<Add close={()=>this.setState({addPopup:false},()=> this.get(1)) }/>}
          onClose={()=>this.setState({addPopup:false})}
          noSubmitBtn
        />


        <FModal
          title='Assign Roles'
          state={this.state.assignRolePopup}
          content={<AssignRoles close={()=>this.setState({assignRolePopup:false},()=>this.get(1))} admin={this.state.admin}/>}
          onClose={()=>this.setState({assignRolePopup:false})}
          noSubmitBtn
          width='50%'
        />

      </div>
    )
  }
}

export default withRouter(List)

import React , { useState, useEffect } from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { Input } from 'reactstrap'
import DatePicker from "react-datepicker"
import { RolesServices, AdminServices } from './../../services'
import moment from 'moment'


export default function AssignRoles({ admin, close }) {

  const [roles, setRoles] = useState([])
  const [rolesRes, setRolesRes] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(()=> getRoles(), [])

  const getRoles = () => {
    RolesServices.get(res=> setRolesRes(res.data) )
  }

  const validate = () =>{
    if (roles.length == 0) alert('Select roles')
    else {
      // console.log('data',data);return
      setLoading(true)
      AdminServices.assignRole(admin.id, {roles: getIds(roles, rolesRes)}, res => {
        setLoading(false)
        close()
      })
    }
  }

  const getIds = (select, res) =>{
    let arr = []
    select.map(s => {
      let item = res.find(r => s == r.name)
      arr.push(item.id)
    })
    return arr
  }

  return(
    <div>

      <FMultiSelector
        title=''
        array={rolesRes.map(e=> e.name)}
        select={setRoles}
        selectedOptions={admin.roles.map(e => e.name)}
      />

      <div className='edit-quotation-btn add-promo' onClick={()=>validate()}>
        <span>Done</span>
      </div>

      <Spinner loading={loading} />

    </div>
  )
}

import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import StarRatings from 'react-star-ratings'
import ReactStars from "react-rating-stars-component";
import { MapMarker } from './../../../components'
import {G} from './../../../core/Global'

export default class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  openMap(){
    // let url = `http://maps.google.com/maps?q=loc:${parseFloat(this.props.order.address.latitude)},${parseFloat(this.props.order.address.longitude)}`
    // window.open(url, "_blank")
  }

  render(){
    return(
      <div className='card'>
        <div className=' space-btw' style={{padding:'2% 30% 0% 0%'}}>
          <div style={{alignSelf:'center'}}>
            <span className='title'>Customer name</span>
            <span className='title-value'>{global.order.customer.enName}</span>
          </div>
          <div style={{alignSelf:'center'}}>
            <span className='title'>Customer phone</span>
            <span className='title-value'>{global.order.customer.phone}</span>
          </div>
        </div>

        <span className='title' style={{margin:'20px 0px 10px 0px'}}>Address</span>
        <div className='address'>
          <div style={{ height: 200, width: '98%' , marginBottom:5}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: G.MAP_API_KEY }}
            defaultZoom={15}
            // layerTypes={['TrafficLayer', 'TransitLayer']}
            options={{draggable: false, keyboardShortcuts:false, clickableIcons:false, scrollwheel: false,fullscreenControl: false}}
            fullscreenControl={false}
            center={{lat: parseFloat(global.order.address.latitude),lng: parseFloat(global.order.address.longitude)}}
            yesIWantToUseGoogleMapApiInternals
            onClick={()=>this.openMap()}
            // style={{zIndex:1}}
          >
            <MapMarker />
          </GoogleMapReact>
        </div>
          <span className='title-value' style={{textAlign:'left', margin:'15  px 0px 10px 0px'}}>{global.order.address.enAddress}</span>
        </div>

        {/* {global.order.rate && (
          <div>
            <span className='title'>Rate</span>
            <StarRatings
              rating={global.order.rate.rate}
              starRatedColor="#000"
              starDimension='20px'
              starSpacing='3px'
              numberOfStars={5}
              name='rating'
            />
          </div>
        )} */}
        {global.order.rate?(
          <div style={{margin:'30px 0px 20px 0px'}}>
            <span className='title'>Rate</span>
            <StarRatings
              rating={global.order.rate.rate}
              starRatedColor="#000"
              starDimension='20px'
              starSpacing='3px'
              numberOfStars={5}
              name='rating'
            />
            <span className='title' style={{marginTop:10}}>{global.order.rate.comment}</span>
          </div>
        ):<div style={{paddingBottom:20}}/>}

      </div>

    )
  }
}

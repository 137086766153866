import React , { useState } from 'react'
import { Input } from 'reactstrap'


export default function FInput({value, setValue, placeholder, maxLength=50, style, title, numbersOnly=false, borderBottom=false, type='text', disabled=false, onKeyDown}) {
  // const [value, setValue] = useState('')
  return(
    <div className={borderBottom?'f-input-bottom':'f-input'}>
      {title&& <span>{title}</span> }
      <Input
        value={value}
        onChange={(e)=>setValue(e.target.value)}
        placeholder={placeholder}
        maxLength={maxLength}
        type={type}
        style={style}
        rows={5}
        disabled={disabled}
        onKeyDown={onKeyDown}
        onInput={e => e.target.value = numbersOnly? e.target.value.match(/^[0-9.]+$/) : e.target.value }
      />
    </div>
  )
}

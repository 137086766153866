import React, {Component} from 'react'
import Invoice from './Invoice'

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  openTap(id){
    let url = window.location.href
    let lastIndex = url.lastIndexOf('/');
    let newUrl = url.substring(0, lastIndex);
    window.open(`${newUrl}/${id}`, "_blank")
  }

  render(){
    return(
      <div className='card rooms'>
        <span className='title' style={{marginBottom:10}}>Rooms</span>
        {global.order.rooms?(
          global.order.rooms.map((room, key)=>(
            <h6 key={key} className='title-value'>
              <span>{room.quantity}</span>
              <i className='mdi mdi-close' style={{margin:'0px 5px'}}/>
              <span>{room.room.enName}</span>
            </h6>
          ))
        ):null}

        {global.order.service.id==2?(
          <div>
            <span className='title'>Created from order</span>
            <span className='title-value click' onClick={()=> this.openTap(global.order.quotation.orderId)}>#{global.order.quotation.orderId}</span>
          </div>
        ):null}

        {global.order.state.toLowerCase() == 'Completed'.toLowerCase() ||
        global.order.state.toLowerCase() == 'done'.toLowerCase()?(
          <>
          {global.order.invoice?
            global.order.invoice.invoiceItems.length!=0?
            <>
            <div className='divider'/>
            <span className='title'>Service</span>
          </>
          :null:null}
          <Invoice forRooms />
        </>
      ):null}
      {global.order.description &&
        <div style={{marginTop:10}}>
          <span className='title'>Description</span>
          <h6 className='title-value'>{global.order.description}</h6>
        </div>
      }
    </div>
  )
}
}

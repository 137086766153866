import React , { useState, useEffect } from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { FixerServices } from './../../services'
import regex from './../../core/Regex'


export default function ChargeWallet({ close }) {

  const { isValidPhoneNumber, isArabic, isMail } = regex

  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)


  const validate = () =>{
    if (!amount.trim() || isArabic(amount) ) alert('Enter valid name')
    else {
      const data = { amount}
      setLoading(true)
      FixerServices.chargeWallet(global.fixer.id, data , res=> {
        setLoading(false)
        res.message=="success" ? close() : alert(res.message)
      })
    }
  }

  return(
    <div className=''>

        <FInput
          placeholder='Amount'
          value={amount}
          setValue={setAmount}
          borderBottom
          numbersOnly
          style={{marginBottom:-20}}
        />


      <div className='edit-quotation-btn add-promo' onClick={()=>validate()}>
        <span>Charge</span>
      </div>

      <Spinner loading={loading} />

    </div>
  )
}

import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { UncontrolledTooltip } from 'reactstrap'
import { OrdersServices } from './../../services'
import { MapMarker, FModal, AvailableFixer, Spinner } from './../../components'
import { mapPlaceholder } from './../../assets'
import { getStateColor } from './../../core/Helper'

import moment from 'moment'
import {G} from './../../core/Global'

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  openMap(){
    let url = `http://maps.google.com/maps?q=loc:${parseFloat(this.props.order.address.latitude)},${parseFloat(this.props.order.address.longitude)}`
    window.open(url, "_blank")
  }

  assignFixer(){
    if (this.AvailableFixerRef.getSelectedFixer().length == 0) alert('Please, select a fixer')
    else {
      let data = {fixerId :this.AvailableFixerRef.getSelectedFixer()[0].id}
      this.setState({loading:true})
      OrdersServices.assignFixer(this.props.order.id, data, (res)=>{
        if (res.message == 'success') this.setState({loading:false, assignFixerPopup:false},()=>this.props.update())
      })
    }
  }

  render(){
    return(
      <div className='order'>

        <div className='space-btw header' style={{backgroundColor: getStateColor(this.props.order.state)}}>
          <span>{this.props.order.service.enName}</span>
          <span>{this.props.order.state}</span>
        </div>

        <div className='space-btw details'>
          <span className='id'>#{this.props.order.id}</span>
          <div>
            <span className='customer'>{this.props.order.customer.enName} - {this.props.order.customer.phone}</span>
            <span className='date'>{moment(this.props.order.requestedDate).format('YYYY/MM/DD')} ({this.props.order.period.enName})</span>
          </div>

        </div>
        <div className='address' style={{paddingBottom:5}}>
          <div className='placeholder' onClick={()=>this.openMap()} style={{ height: 120, width: '100%' , marginBottom:5}}>
            {/* <GoogleMapReact
              bootstrapURLKeys={{ key: G.MAP_API_KEY }}
              defaultZoom={15} fullscreenControl={false} yesIWantToUseGoogleMapApiInternals
              options={{draggable: false, keyboardShortcuts:false, clickableIcons:false, scrollwheel: false,fullscreenControl: false}}
              center={{lat: parseFloat(this.props.order.address.latitude),lng: parseFloat(this.props.order.address.longitude)}}
              onClick={()=>this.openMap()}>
              <MapMarker />
            </GoogleMapReact> */}
            {/* <img src={mapPlaceholder}/> */}
          </div>
          <span id={`addressTooltip${this.props.order.id}`}>{this.props.order.address.enAddress}</span>
          <UncontrolledTooltip placement="top" target={`addressTooltip${this.props.order.id}`}>
            {this.props.order.address.enAddress}
          </UncontrolledTooltip>
        </div>

        {this.props.order.state.toLowerCase() == 'Confirmed'.toLowerCase() ||
        this.props.order.state.toLowerCase() == 'Pending'.toLowerCase() ||
        this.props.order.state.toLowerCase() == 'Assigned'.toLowerCase()?(
          <div className=' open space-btw' onClick={()=>this.setState({assignFixerPopup:true})}>
            <span className='title'>Fixer: {this.props.order.fixer? this.props.order.fixer.enName?this.props.order.fixer.enName:this.props.order.fixer.arName: 'Assign fixer ...'}</span>
            <i className='mdi mdi-chevron-right' />
          </div>
        ):(
          <div className=' open space-btw'>
            <span className='title'>Fixer: {this.props.order.fixer? this.props.order.fixer.enName?this.props.order.fixer.enName:this.props.order.fixer.arName: 'Assign fixer ...'}</span>
          </div>
        )}

        <div className='open space-btw' onClick={()=>this.props.goOrder()}>
          <span className='title'>Open order</span>
          <i className='mdi mdi-chevron-right' />
        </div>

        <FModal
          title={`Assign fixer to order ${this.props.order.id}`}
          state={this.state.assignFixerPopup}
          content={<AvailableFixer order={this.props.order} ref={ref=>this.AvailableFixerRef=ref}/>}
          onClose={()=>this.setState({assignFixerPopup:false})}
          onSubmit={()=>this.assignFixer()}
        />
        <Spinner loading={this.state.loading} />
      </div>
    )
  }
}

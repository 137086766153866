import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Label,FormGroup, Modal} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { Logo } from './../../assets'
import { G } from './../../core/Global'
import { AuthServices } from './../../services'
import { getMessaging, getToken } from "firebase/messaging";

class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
      phone:'',
      password:[],
      loading:false,
      token:'',
      isGrand: true
    }
    this.handleChangeInputs = this.handleChangeInputs.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }
//
  componentDidMount(){
    this.gettingToken()
  }

  gettingToken(){
    const messaging = getMessaging();
      getToken(messaging, { vapidKey: 'BNRyNou366NCaZJ4SR9n9ZNmzJmoPDnFYhSMfXUr5FiRdGigu0RmXegBQlUErN-t0UWYYQBeIaPZ0E-3d-ZBo4A' }).then((currentToken) => {
      if (currentToken) this.setState({token:currentToken, isGrand:true})
    }).catch((err) => this.setState({isGrand: false},()=>console.log('err',err)) )
  }

  handleChangeInputs = (x, e) => {
    switch (x) {
      case 'phone':
      this.setState({phone:e.target.value})
      break
      case 'password':
      this.setState({password:e.target.value})
      break
      default:
    }
  }


  handleKeyPress(target) {
    if(target.charCode==13) this.login()
  }


  login(){
    if (this.state.phone == '' || !this.state.phone.replace(/\s/g, '').length) {
      alert('Enter your phone number')
    }else if (!this.state.phone.startsWith('01')) {
      alert('Phone number must be starts with 01')
    }else if (this.state.phone.length !== 11) {
      alert('Phone number must be 11 digits at least')
    }else if (this.state.phone.match(/^[0-9]+$/) == null) {
      alert('Enter vaild phone number')
    }else if (this.state.password == '' || !this.state.password.replace(/\s/g, '').length) {
      alert('Enter your password')
    }else if (this.state.password.length < 6) {
      alert('Enter vaild password')
    }if (this.state.token == '') {
      alert('Grant notification permission')
    }else {
      this.setState({loading:true})
      let data = {
        phone : this.state.phone,
        password : this.state.password,
        platformTypeId: 3,
        userTypeId:3,
        pushNotificationToken: this.state.token
      }
      console.log(data);
      AuthServices.login(data,(res)=>{
        this.setState({loading:false})
        if (res.message === 'success') {
          localStorage.setItem('AmalLogged','home')
          localStorage.setItem('AmalUserData',JSON.stringify(res.data))
          this.props.history.push('/')
        }else if (res.message == 'wrong_phone_or_password') {
          alert('Phone number or Password is wrong')
        }else if (res.message == 'blocked') {
          alert('Your account is blocked')
        }else {
          console.log(res);
        }
      })
    }
  }




  render() {
    return (
      <div className="app flex-row align-items-center auth">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
                <Card className="p-4">
                  <CardBody>
                      <img src={Logo} style={{display:'block',margin:'auto',width:150,height:65, marginBottom:20}}/>
                      <h1 style={{textAlign:'center'}}>Login</h1>
                      <p className="text-muted" style={{textAlign:'center',marginTop:20}}>Sign In to your account</p>

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="mdi mdi-account"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.phone}
                          onChange={(e)=>this.handleChangeInputs('phone',e)}
                          type="text"
                          placeholder="Phone Number"
                          autoComplete="username"
                          maxLength={11}
                          onKeyPress={this.handleKeyPress}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="mdi mdi-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.password}
                          onChange={(e)=>this.handleChangeInputs('password',e)}
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          onKeyPress={this.handleKeyPress}
                        />
                      </InputGroup>

                      {!this.state.isGrand &&
                      <span className='no-permssion' onClick={()=>window.open('https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop', '_blank')}>Notification Permssion is required, Click here to get help.</span>
                    }

                      <Row style={{marginTop:12}} className="justify-content-center">
                        <Col xs="4" className="text-center">
                          <Button onClick={()=>this.login()} style={{backgroundColor:G.baseColor,borderWidth:1,borderColor:'white',color:'#fff',marginTop:15}}  className="px-4">Login</Button>
                        </Col>
                      </Row>

                  </CardBody>
                </Card>
            </Col>
          </Row>

          {/* loading spinner */}
          <Modal isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>

        </Container>
      </div>
    )
  }
}

export default withRouter(Login)

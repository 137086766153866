import React , { useState, useEffect } from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Select from 'react-select'
import { Button } from 'reactstrap'
import { Services } from './../../services'
import { FInput } from './../../components'
import {useForceUpdate} from './../../core/Helper'


export default function Filter({byCities, byAreas, byServices, byState, byDate, bySearchTerm, setQuery, defaultCity, byIsBlocked, stateFor, isSingleCity=false }) {

  const [searchTerm, setSearchTerm] = useState('')
  const [cities, setCities] = useState(null)
  const [areas, setAreas] = useState([])
  const [services, setServices] = useState(null)
  const [status, setStatus] = useState(null)
  const [selectedCities, setSelectedCities] = useState([])
  const [selectedAreas, setSelectedAreas] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [selectedIsBlocked, setSelectedIsBlocked] = useState([])
  // const [selectedDate, setSelectedDate] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)

  const forceUpdate = useForceUpdate()

  useEffect(()=> {
    byCities && getCities()
    byServices && getServices()
    byState && getStatus()
    setSelectedDates(setDate(new Date(),new Date()))
  } ,[])

  const getCities = () => Services.getCities(res=> {
    setCities(res.data.cities)
  })

  const getServices = () => Services.getServices(res=> setServices(res.data))

  const getStatus = () => Services.getStatus(stateFor, res=> setStatus(res.data))

  const getAreas = (id) => Services.getAreas(id, res=> setAreas(res.data))

  const onSelect = (ops, act, set, type) => {
    if (act.action === 'clear') set([])
    else set(ops)

    if (type=='city' && isSingleCity) { getAreas(ops.value); setSelectedAreas([]) }
  }

  const clear = () => {
    setSelectedCities([])
    setSelectedAreas([])
    setSelectedServices([])
    setSelectedStatus([])
    setSelectedIsBlocked([])
    setSelectedDates(setDate(new Date(),new Date()))
    setSearchTerm('')
    setQuery('')
  }
  const filter = () => {
    let query = ''

    if (isSingleCity && selectedCities.length != 0 ) query+=`city=${selectedCities.value}&`
    else selectedCities.map((e,k)=> query+=`cities[${k}]=${e.value}&`)

    selectedAreas.map((e,k)=> query+=`areas[${k}]=${e.value}&`)

    selectedServices.map((e,k)=> query+=`services[${k}]=${e.value}&`)

    selectedStatus.map((e,k)=> query+=`states[${k}]=${e.label}&`)

    selectedIsBlocked.map((e,k)=> query+=`blocked=${e.value}&`)

    // if (selectedDate && !moment(selectedDate).isSame(Date.now(), 'day') ) query+= 'requestedDate='+moment(selectedDate).format('YYYY-MM-DD')+'&'
    if (selectedDates) {
      query+= 'requestedDateFrom='+moment(selectedDates.startDate).format('YYYY-MM-DD')+'&'
      query+= 'requestedDateTo='+moment(selectedDates.endDate).format('YYYY-MM-DD')+'&'
    }

    if (searchTerm!='') query+= 'searchTerm='+searchTerm+'&'

    query = '?'+query

    let final = query.slice(0, -1)

    setQuery(final)
  }

  const isEnter = (event) => { event.keyCode === 13 &&  filter()}

  const onApply = (event, picker) => {
    setSelectedDates(setDate(picker.startDate,picker.endDate))
  }

  const setDate = (start, end) => {
    let obj = {
      startDate:moment(start).format('YYYY-MM-DD'),
      endDate:moment(end).format('YYYY-MM-DD'),
    }
    return obj
  }

  return(
    <div className='report-filter order-filter flex-row flex-end'>


      {cities && byCities &&
        <Select
          closeMenuOnSelect={false}
          isMulti={!isSingleCity}
          options={cities.map(e=>({value:e.id,label:e.enName}))}
          style={{width: '-webkit-fill-available'}}
          placeholder='City..'
          value={selectedCities}
          onChange={(ops, act)=> onSelect(ops, act, setSelectedCities, 'city')}
        />
      }

      {areas && byAreas &&
        <Select
          closeMenuOnSelect={false}
          isMulti isDisabled={selectedCities && selectedCities.length == 0}
          options={areas.map(e=>({value:e.id,label:e.enName}))}
          style={{width: '-webkit-fill-available'}}
          placeholder='Area..'
          value={selectedAreas}
          onChange={(ops, act)=> onSelect(ops, act, setSelectedAreas)}
        />
      }

      {services && byServices &&
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={services.map(e=>({value:e.id,label:e.enName}))}
          style={{width: '-webkit-fill-available'}}
          placeholder='Service..'
          value={selectedServices}
          onChange={(ops, act)=> onSelect(ops, act, setSelectedServices)}
        />
      }

      {status && byState &&
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={status.map((e,k)=>({value:k,label:e}))}
          style={{width: '-webkit-fill-available'}}
          placeholder='State..'
          value={selectedStatus}
          onChange={(ops, act)=> onSelect(ops, act, setSelectedStatus)}
        />
      }


      {byIsBlocked &&
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={[{value:1,label:'Blocked'},{value:0,label:'Un blocked'}]}
          style={{width: '-webkit-fill-available'}}
          placeholder='Is blocked..'
          value={selectedIsBlocked}
          onChange={(ops, act)=> onSelect(ops, act, setSelectedIsBlocked)}
        />
      }

      {selectedDates && byDate &&
        // <DateRangePicker
        //   autoApply={false}
        //   autoUpdateInput={false}
        //   initialSettings={{
        //     startDate: moment(new Date()).toDate(),
        //     locale:{format:'YYYY-MM-DD'},
        //     autoApply:true, singleDatePicker:true
        //   }}
        //   onApply={(event, picker)=>setSelectedDate(picker.startDate)}>
        //   <div className="date-input flex-row">
        //     <span><i className='mdi mdi-calendar-month'/> {moment(selectedDate).format('YYYY-MM-DD')} </span>
        //   </div>
        // </DateRangePicker>
        <DateRangePicker
          autoApply={false}
          autoUpdateInput={false}
          initialSettings={{
            startDate: moment(new Date()).toDate(),
            endDate: moment(new Date()).toDate() ,
            locale:{format:'YYYY-MM-DD'},
            autoApply:true
          }}
          onApply={onApply}>
          <div className="date-input flex-row">
            <i className='mdi mdi-calender'/>
            <span>
              {moment(selectedDates.startDate).format('YYYY-MM-DD') +' - ' + moment(selectedDates.endDate).format('YYYY-MM-DD')}
            </span>
          </div>
        </DateRangePicker>
      }

      {bySearchTerm &&
        <FInput
          placeholder='Search by name, phone..'
          value={searchTerm}
          setValue={setSearchTerm}
          borderBottom
          onKeyDown={isEnter}
          style={{minWidth:180, marginBottom:5}}
        />
      }


      <Button className='submit-btn' onClick={filter}>Filter</Button>

      <span onClick={clear} className='clear'>Clear</span>

    </div>
  )
}

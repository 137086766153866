import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class ItemServices extends Component {

  static getAll(query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/pricing-items${query}`).then(callBack)
  }

  static add(data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/pricing-items`, data).then(callBack)
  }

}

import React, { Component, Fragment } from 'react';
import { Button, Container } from 'reactstrap'
import routes from '../../core/routes'
import { OrdersServices } from './../../services'
import { sound1 } from './../../assets'
import { Spinner, Order, Paginator, Filter } from './../../components'

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders:[],
      pages:'',
      noOrders:false,
      page:1,
      query:''
    }
  }

  componentDidMount(){
    window.goLogin = () => {
      this.props.history.push('/login')
    }
    window.goTo = (screen) => {
      this.props.history.push(screen)
    }

    window.pushNewOrder = (payload) => {
      if (window.location.href.includes('/orders')) {
        new Audio(sound1).play()
        let orders = this.state.orders
        orders.unshift(JSON.parse(payload.data.order))
        this.setState({orders})
      }
    }

    window.orderUpdate = (payload) => {
      if (window.location.href.includes('/orders')) {
        new Audio(sound1).play()
        let orders = this.state.orders
        let order = JSON.parse(payload.data.order)
        orders[orders.findIndex(el => el.id === order.id)] = order
        this.setState({orders},()=>this.forceUpdate())
      }
    }

    this.get(this.props.location.page ? this.props.location.page : this.state.page)
  }

  get(page ){
    this.setState({loading:true})
    OrdersServices.getAll(page, this.state.query, (res)=>{
      this.setState({loading:false, orders:res.data, page:res.pages.currentPages,  pages:res.pages, noOrders:res.data.length==0},()=>{
        document.body.scrollTo({top: 0, behavior: 'smooth'})
      })
    })
  }


  goOrder(order){
    global.order = order
    this.props.history.push({ pathname: `orders/${order.id}`, page: this.state.page })
  }

  setQuery(query){
    this.setState({query:query.replace('?','&')}, ()=> this.get(1))
  }

  render() {
    return (
      <div className='main-orders'>


        <Filter byCities byAreas byServices byState byDate bySearchTerm stateFor='orders' isSingleCity setQuery={(q)=>this.setQuery(q)} />

        {this.state.noOrders?(
          <div className='no-data'>
            <p>😕</p>
            <span>There is no orders, try change filter options.</span>
          </div>
        ):(
          <Fragment>
            <div className='flex-row orders' >
              {this.state.orders.map((order, key)=>
                <Order order={order} key={key} goOrder={()=>this.goOrder(order)} update={()=>this.get(this.state.page)}/>
              )}
            </div>

            {this.state.pages==''? null : <Paginator pages={this.state.pages} get={(page)=>this.get(page)}/> }
          </Fragment>

        )}

        <Spinner loading={this.state.loading}/>

      </div>
    )
  }
}

import React, {Component} from 'react'
import { ItemServices } from './../../services'

export default class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: this.props.count
    }
  }

  componentDidMount(){
    this.setState({count: this.props.count})
  }


  minus(){
    let count = this.state.count
    if (count == 1) return;
    this.setState({count: count-1},()=>this.forceUpdate())
    this.props.setCount(count-1)
  }

  plus(){
    let count = this.state.count
    this.setState({count: count+1},()=>this.forceUpdate())
    this.props.setCount(count+1)
  }

  getCount(){
    return this.state.count
  }

  render(){
    return(
      <div className='flex-row counter'>
        <i className='mdi mdi-minus' onClick={()=>this.minus()} style={{color:this.state.count==1?'#777':'#52c3c2'}}/>
        <span>{this.state.count}</span>
        <i className='mdi mdi-plus' onClick={()=>this.plus()}/>
      </div>
    )
  }
}

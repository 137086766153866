import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class CompalintsServices extends Component {

  static get(page, query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/complaints?page=${page}${query}`).then(callBack)
  }

  static single(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/complaints/${id}`).then(callBack)
  }

  static updateStatus(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/complaints/${id}/state`, data).then(callBack)
  }

  static send(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/complaints/${id}/reply`, data).then(callBack)
  }

}

import React, { Component } from 'react'

export default class Paginator extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <div className='paginator flex-row' >

        {this.props.pages.previousPages.length == 0 ?(
          <i className='mdi mdi-chevron-left item icon'/>
        ):(
          <i className='mdi mdi-chevron-left item icon active-icon' onClick={()=>this.props.get(this.props.pages.currentPages-1)}/>
        )}


          {this.props.pages.previousPages.map((p, k)=>(
            <span key={k} className='item' onClick={()=>this.props.get(p)}>{p}</span>
          ))}

          <span className='active'>{this.props.pages.currentPages}</span>

          {this.props.pages.nextPages.map((p, k)=>(
            <span key={k} className='item' onClick={()=>this.props.get(p)}>{p}</span>
          ))}


          {this.props.pages.nextPages.length == 0 ?(
            <i className='mdi mdi-chevron-right item icon'/>
          ):(
            <i className='mdi mdi-chevron-right item icon active-icon' onClick={()=>this.props.get(this.props.pages.currentPages+1)}/>
          )}

      </div>
    )
  }
}

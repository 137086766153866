import React, { Component, Fragment } from 'react';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Logo, sound1 } from './../../assets'
import { G } from './../../core/Global'
import { CompalintsServices } from './../../services'
import { Spinner, ListComplaints, Chat, Filter } from './../../components'
import {chatPlaceholder} from './../../assets'

let page = 1

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      data:[],
      noMore:false,
      query:'',
    }
  }
  componentDidMount(){
    page = 1
    this.get(page)

    window.newComplaint = (payload) => {
      if (window.location.href.includes('/complaints')) {
        let audio = new Audio(sound1)
        audio.play()
        Object.keys(payload.data).map((key, index)=> {
          if (payload.data[key] == 'complaint') {
            let data = this.state.data
            data.unshift(JSON.parse(payload.data.complaint))
            this.setState({data})
          }
        })
      }
    }
    
  }

  get(page){
    this.setState({loading:true})
    CompalintsServices.get(page, this.state.query, (res)=>{
      this.setState(pS=>({
        loading:false,
        data:pS.data.concat(res.data.complaints),
        noData:pS.data.concat(res.data.complaints).length==0,
        noMore:res.data.complaints.length==0}
      ))
    })
  }

  getMore(){
    page+=1
    this.get(page)
  }

  select(e){
    this.setState({selected:e})
  }

  goOrder(){
    global.order = {id: this.state.selected.order.id}
    window.open(`/orders/${this.state.selected.order.id}`, '_blank')
  }
  goUser(){
    if (this.state.selected.userTypeId == 1) {
      global.customer = {id: this.state.selected.userId}
      window.open(`/customers/${this.state.selected.userId}`, '_blank')
    }else {
      global.fixer = {id: this.state.selected.userId}
      window.open(`/fixers/${this.state.selected.userId}`, '_blank')
    }
  }

  setQuery(query){
    this.setState({data:[],query:query.replace('?','&')}, ()=> this.get(1))
  }

  render() {
    return (
      <Fragment>

        <Filter byCities byState bySearchTerm stateFor='complaints' setQuery={(q)=>this.setQuery(q)} />

        <div className="flex-row main-items chat">

          <ListComplaints data={this.state.data} next={()=>this.getMore()} noMore={this.state.noMore} select={(e)=>this.select(e)}/>

          {this.state.noData?(
            <div className='no-data'>
              <p>😕</p>
              <span>There is no complaints.</span>
            </div>
          ):(
            this.state.selected?
            <Chat chat={this.state.selected} goOrder={()=> this.goOrder()} goUser={()=> this.goUser()} />
            :(
              <div className='placeholder'>
                <img src={chatPlaceholder} alt="loading..." style={{}}/>
              </div>
            )
          )}

          <Spinner loading={this.state.loading} />
        </div>
      </Fragment>
    )
  }
}

export default withRouter(List)

import React , { useState, useEffect } from 'react'
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { G } from './../../core/Global'
import { CompalintsServices } from './../../services'


export default function Header({ chat, goOrder, goUser, updateChat }) {
  const [status, setStatus] = useState(chat.state)

  useEffect(()=> setStatus(chat.state),[chat,chat.state])
  console.log('chat',chat);
  const update = (e) => {
    setStatus(e)
    chat.state = e
    CompalintsServices.updateStatus(chat.id, {state:e} , res=> updateChat(chat))
  }

  return(
    <div className='header space-btw'>
      <UncontrolledDropdown setActiveFromChild  >
        <DropdownToggle tag="a" data-toggle="dropdown" className='status'>
          <div className='menu-toggle'>

            {status}
              <i className='mdi mdi-chevron-down' />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {status=='new'?
          G.complainStatus.filter(e=> e != status).map((e,k) =>(
            <DropdownItem tag="a" key={k} className='filter-item' onClick={()=>update(e)}> {e} </DropdownItem>
          )):
          G.complainStatus.filter(e=> e != status && e != 'new').map((e,k) =>(
            <DropdownItem tag="a" key={k} className='filter-item' onClick={()=>update(e)}> {e} </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>

      <div className='item'>
        <span>Complain #{chat.id}</span>
        <span>{chat.description}</span>
      </div>
      <div>
        <span className='com-order' onClick={goOrder} >Order <span>#{chat.order.id}</span></span>
        <span className='com-order' onClick={goUser} >   {chat.userTypeId==1?'Customer':'Fixer'} <span>#{chat.userId}</span></span>
      </div>

    </div>
  )
}

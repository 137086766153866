

const isNumber = (text) => text.match(/^(-?\d*)((\.(\d{0,2})?)?)$/)

const isInteger = (text) => text.match(/^[0-9]+$/)

const isArabic = (text) => text.match(/[\u0600-\u06FF]/)

const isMail = (mail) => mail.match(/^\S+@\S+\.\S+$/)


const isValidPhoneNumber = (phone) =>{
  if (!phone.startsWith('01')) return 'Phone number must be starts with 01'
  else if (phone.length !== 11) return 'Phone number must be 11 digits at least'
  else if (phone.match(/^[0-9]+$/) == null) return 'Enter vaild phone number'
  else return true
}

const validateUrl = (url) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(url)
}

export default {
  isNumber,
  isValidPhoneNumber,
  isArabic,
  isMail,
  isInteger
}

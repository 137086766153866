import React, { Component } from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { G } from './../../core/Global'
import { CustomerServices } from './../../services'
import { Spinner, Paginator, FModal, AddNote, Notify, Filter } from './../../components'

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      customers:[],
      cities:[],
      query:'',
      selectCity:'',
      noData:false,
      pages:'',
      selectedFixer:'',
      page:1
    }
  }

  componentDidMount(){
    this.get(this.props.location.page ? this.props.location.page : this.state.page)
  }

  get(page){
    this.setState({loading:true})
    CustomerServices.getAll(page, this.state.query, res => {
      this.setState({loading:false, customers:res.data.customers, page:res.data.pages.currentPages, pages:res.data.pages, noData:res.data.customers.length==0})
    })
  }

  goCustomer(customer){
    global.customer = customer
    this.props.history.push({ pathname: `customers/${customer.id}`, page: this.state.page })
  }

  setQuery(query){
    this.setState({query:query.replace('?','&')}, ()=> this.get(1))
  }

  render() {
    return (
      <div className="customers">
        <h1>Customers</h1>

        <Filter bySearchTerm setQuery={(q)=>this.setQuery(q)} />
        {this.state.loading?null:
          !this.state.noData?(
            <>
            <Table>
              <thead>
                <tr>
                  <th className='col-title first'>id</th>
                  <th className='col-title first'>customer name</th>
                  <th className='col-title'>phone</th>
                  <th className='col-title'>join date</th>
                  <th className='col-title'>orders number</th>
                  <th className='col-title'>is-blocked</th>
                  <th className='col-title'></th>
                </tr>
              </thead>
              <tbody>
                {this.state.customers.map((e,k)=>(
                  <tr key={k} >
                  <th className='col-value first' onClick={()=>this.goCustomer(e)}>{e.id}</th>
                  <th className='col-value first' onClick={()=>this.goCustomer(e)}>{e.name}</th>
                  <th className='col-value'>{e.phone}</th>
                  <th className='col-value'>{e.createdAt}</th>
                  <th className='col-value'>{e.ordersCount}</th>
                  <th className='col-value'>{e.isBlocked?'Blocked':'Avaiable'}</th>
                  <th className='col-value'>
                    <UncontrolledDropdown setActiveFromChild>
                      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={this.state.menu} >
                        <i className='mdi mdi-dots-vertical'/>
                      </DropdownToggle>
                      <DropdownMenu>
                        <div onClick={()=>this.setState({user:e, addNotePopup:true})}>Add Note</div>
                        <div onClick={()=>this.setState({user:e,notifyType:'notification',notifiyPopup:true})}>Send push notification</div>
                        <div onClick={()=>this.setState({user:e,notifyType:'sms',notifiyPopup:true})}>Send SMS</div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          {this.state.pages==''? null : <Paginator pages={this.state.pages} get={(page)=>this.get(page)}/> }
          </>
        ):(
          <div className='no-data'>
            <p>😕</p>
            <span>There is no customers</span>
          </div>
        )}

        <FModal
          title='Add Note'
          state={this.state.addNotePopup}
          content={<AddNote user={this.state.user} close={()=>this.setState({addNotePopup:false},()=> this.get(this.state.page))}/>}
          onClose={()=>this.setState({addNotePopup:false})}
          noSubmitBtn
        />

        <FModal
          title={`Send ${this.state.notifyType}`}
          state={this.state.notifiyPopup}
          content={<Notify user={this.state.user} type={this.state.notifyType} close={()=>this.setState({notifiyPopup:false})}/>}
          onClose={()=>this.setState({notifiyPopup:false})}
          noSubmitBtn
        />

      </div>
    )
  }
}

export default withRouter(List)

import React, { Component, Suspense } from 'react'
import { AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav } from '@coreui/react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import navigation from '../../core/_nav'
import routes from '../../core/routes'
import Header from './Header'

class Content extends Component {

  loading = () => <i className='fa fa-circle-o-notch fa-spin' style={{display:'block', textAlign:'center', fontSize:30, color:'#777'}}/>

  signOut(e) {
    e.preventDefault()
    this.props.history.push('/login')
  }

  render() {
    let isLogged = localStorage.getItem('AmalLogged')
    return (
      <div className="app">

        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
            <Header onLogout={e=>this.signOut(e)}/>
          </Suspense>
        </AppHeader>

        <div className="app-body">
          <AppSidebar fixed display="lg">

            <AppSidebarHeader />

            <AppSidebarForm />

            <Suspense>
              <AppSidebarNav navConfig={navigation} {...this.props} />
            </Suspense>

            <AppSidebarFooter />

          </AppSidebar>
          <main className="main">


            {/* <Container style={{padding:0}}> */}
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      >
                        {route.hasChild&&route.isChild?(
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => <route.component {...props} />}
                          />
                        ):null}
                      </Route>
                    ):null
                  }
                )}
                {isLogged == null? <Redirect from="/" to="/login" />
                :isLogged == 'home'? <Redirect from="/" to="/orders" />
                :<Redirect from="/" to="/login" />
              }
            </Switch>
          </Suspense>

        {/* </Container> */}

      </main>

    </div>
  </div>
)
}
}

export default withRouter(Content);

import React , { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import {useForceUpdate} from './../../core/Helper'

export default function FMultiSelector({array=[], type='one', select, title, selectedOptions=[]}) {
  const [selected, setSelected] = useState([])
  const forceUpdate = useForceUpdate()

  useEffect(()=> setSelected(selectedOptions) ,[])

  const onSelect = (i) => {
    let arr = [...selected]
    arr.includes(i) ? arr.splice(arr.indexOf(i),1) : arr.push(i)
    setSelected(arr)
    select(arr)
    forceUpdate()
    // console.log(arr);
  }

  return(
    <div className='f-multi-selector'>
      { title && <span>{title}</span> }
      { array.map((i,k)=>(
        <div key={k}>
          <div className='option' onClick={()=> onSelect(i)}>
             <i className={ selected.includes(i) ? 'mdi mdi-checkbox-marked':'mdi mdi-checkbox-blank-outline'}/> {i}
           </div>
        </div>
      ))}
    </div>
  )
}
//

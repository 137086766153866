import React, { Component } from 'react';
import { Button, Container } from 'reactstrap'
import routes from '../../core/routes'
import { OrdersServices } from './../../services'
import { Spinner, Summery } from './../../components'
import { sound1 } from './../../assets'

let page = 1

export default class Single extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders:[],
      selectFilter:'Fixture',
      loading:true
    }
  }

  componentDidMount(){
    this.getOrder(global.order? global.order.id : this.props.match.params.id)
    window.goTo = (pathname) => {
      this.props.history.push({pathname})
    }
    window.orderUpdate = (payload) => {
      if (window.location.href.includes('/orders/')) {
        new Audio(sound1).play()
        this.getOrder(global.order? global.order.id : this.props.match.params.id)
      }
    }
  }

  getOrder(id){
    this.setState({loading:true})
    OrdersServices.get(id,(res)=>{
      global.order = Object.assign( {}, global.order, res.data )
      this.setState({loading:false, orders:res})
    })
  }

  goBack(){
    this.props.history.push({ pathname: `/orders`, page: this.props.location.page })
  }

  update(){
    this.getOrder(global.order? global.order.id : this.props.match.params.id)
  }

  render() {
    return (
      <div style={{backgroundColor:'#fafafa', minHeight:window.innerHeight-55}}>
        <div className='flex-row single-order'>

          <div className='back' onClick={()=>this.goBack()}>
            <i className='mdi mdi-chevron-left' style={{fontSize:30}}/>
          </div>

          {this.state.loading?null: <Summery update={()=>this.update()}/>}

        </div>

        <Spinner loading={this.state.loading}/>
      </div>
    )
  }
}

import React, { useEffect, useState, Fragment } from 'react'
import { OrdersServices } from './../../../services'
export default function Tasks({}) {

  const [data, setDate] = useState(null)

  useEffect(()=> getReport() ,[])

  const getReport = () => {
    OrdersServices.getReport(global.order.id, res=> setDate(res.data) )
  }

  return(
    data && (
      <div className='card rooms'>
        <span className='title' style={{marginBottom:10}}>Report</span>

        <div className='space-btw days'>
          <span className='parent'>Fixing price</span>
          <span className='parent'>{data.fixingPrice.toFixed(2)} EGP</span>
        </div>
        <div className='divider'/>


        <div className='space-btw days'>
          <span className='parent'>Transportation price</span>
          <span className='parent'>{data.transportationPrice} EGP</span>
        </div>
        <div className='divider'/>

        <div className='space-btw days'>
          <span className='parent'>Tools price</span>
          <span className='parent'>{data.toolsPrice} EGP</span>
        </div>
        <div className='divider'/>

        <span className='title' style={{marginBottom:10}}>Items</span>
        {data.reportItems.map((room, key)=>(
          <h6 key={key} className='title-value'>
            <span>{room.quantity}</span>
            <i className='mdi mdi-close' style={{margin:'0px 5px'}}/>
            <span>{room.item.name}</span>
          </h6>
        ))}
        <div className='divider'/>

        <div className='days'>
          <span className='child'>Working days</span>
          <span className='parent'>{data.workingDays} days</span>
        </div>



      </div>
    )
  )
}

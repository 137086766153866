import React, { Component } from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { G } from './../../core/Global'
import { RolesServices } from './../../services'
import { Spinner, Paginator, FModal, Permissions } from './../../components'

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      data:[],
      isEdit:false
    }
  }

  componentDidMount(){
    this.get()
  }

  get(page){
    this.setState({loading:true})
    RolesServices.get(res=> this.setState({loading:false, data:res.data}))
  }

  goCustomer(customer){
    global.customer = customer
    this.props.history.push(`customers/${customer.id}`)
  }

  render() {
    return (
      <div className="flex-row main-items promo">
        <div className="items">
          <div className='space-btw title'>
            <h1>Roles</h1>
            <div className='f-btn btn-add' onClick={()=>this.setState({addPopup:true,role:''})}>
              <span>Add Role</span>
            </div>
          </div>
          {this.state.loading?null:
            !this.state.noData?(
              <Table>
                <thead>
                  <tr>
                    <th className='col-title first'>id</th>
                    <th className='col-title first'>role name</th>
                    <th className='col-title' width='70%'>permission</th>
                    <th className='col-title'></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((e,k)=>(
                    <tr key={k} >
                      <th className='col-value first'>{e.id}</th>
                      <th className='col-value first'>{e.name}</th>
                      <th className='col-value'>
                        {e.permissions.map((i,k)=>(
                          <span key={k}>{i.name}, </span>
                        ))}
                      </th>
                      <th className='col-value'>
                          {/* <i className='mdi mdi-delete'/> */}
                          <i className='mdi mdi-pencil' onClick={()=>this.setState({addPopup:true,role:e})}/>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ):(
              <div className='no-data'>
                <p>😕</p>
                <span>There is no roles</span>
              </div>
            )}

            <FModal
              title='Role'
              state={this.state.addPopup}
              content={<Permissions close={()=>this.setState({addPopup:false},()=>this.get())} role={this.state.role}/>}
              onClose={()=>this.setState({addPopup:false})}
              noSubmitBtn
              width='50%'
            />
          </div>

        </div>
      )
    }
  }

  export default withRouter(List)

  const mock = [
    {name:'Manger',permissions:'permission1, permission2'},
    {name:'Supervisor',permissions:'permission1, permission2, permission3'},
    {name:'Modriate',permissions:'permission1'},
  ]

import React , { useState, useEffect } from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { AdminServices } from './../../services'
import regex from './../../core/Regex'


export default function Add({ close }) {

  const { isValidPhoneNumber, isArabic, isMail } = regex

  const [name, setName] = useState('')
  const [arName, setArName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)


  const validate = () =>{
    if (!name.trim() || isArabic(name) ) alert('Enter valid name')
    else if (!arName.trim() || !isArabic(arName) ) alert('Enter valid arabic name')
    else if (!email.trim() || !isMail(email) ) alert('Enter valid email')
    else if (!phone.trim()) alert('Enter phone')
    else if (isValidPhoneNumber(phone) != true) alert(isValidPhoneNumber(phone))
    else if (!type.trim()) alert('Select gender')
    else {
      const data = { name, arName, email, phone, gender: type.toLowerCase() }
      setLoading(true)
      AdminServices.add(data , res=> {
        setLoading(false)
        res.message=="success" ? close() : alert(res.message)
      })
    }
  }

  return(
    <div className=''>

        <FInput
          placeholder='English Name'
          value={name}
          setValue={setName}
          borderBottom
        />

        <FInput
          placeholder='Arabic Name'
          value={arName}
          setValue={setArName}
          borderBottom
        />

        <FInput
          placeholder='Email'
          value={email}
          setValue={setEmail}
          borderBottom
        />

        <FInput
          placeholder='Phone'
          value={phone}
          setValue={setPhone}
          numbersOnly
          borderBottom
          maxLength={11}
        />

        <FSwitcher
          title='Gender'
          array={['Male','Female']}
          select={setType}
        />



      <div className='edit-quotation-btn add-promo' onClick={()=>validate()}>
        <span>Done</span>
      </div>

      <Spinner loading={loading} />

    </div>
  )
}

import React, {Component, Fragment} from 'react'
import { OrdersServices } from './../../../services'
import { Spinner, FModal } from './../../../components'
import { Input } from 'reactstrap'
import { isNumber } from './../../../core/Helper'
import Items from './Items'
import {isAllowedToEditQuotation} from './../../../core/Helper'

export default class EditQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixingPrice: global.order.quotation.fixingPrice,
      transportationPrice: global.order.quotation.transportationPrice,
      workingDays: global.order.quotation.workingDays,
      notes: global.order.quotation.notes?global.order.quotation.notes:'',
      loading:false,
    }
  }

  componentDidMount(){
    if (Object.keys(global.quotationData).length != 0) {
      this.setState({
        fixingPrice: global.quotationData.fixingPrice,
        transportationPrice: global.quotationData.transportationPrice,
        workingDays: global.quotationData.workingDays,
        notes: global.quotationData.notes?global.quotationData.notes:'',
      })
    }
  }

  onChange(e, type){
    if (isNumber(e.target.value) || e.target.value == 0) {
      this.setState({[type] : e.target.value})
      // global.order.quotation[type] = e.target.value
    }else {
      alert('Please, enter valid numbers')
    }
  }
  editItems(){
    global.idEditItem = true
    global.quotationItems = global.order.quotation.items
    global.quotationData = {
      fixingPrice:this.state.fixingPrice,
      transportationPrice:this.state.transportationPrice,
      workingDays:this.state.workingDays,
      notes:this.state.notes,
    }
    window.goTo('/items')
  }

  edit(){
    let data = {
      fixingPrice:this.state.fixingPrice,
      transportationPrice:this.state.transportationPrice,
      workingDays:this.state.workingDays,
      notes:this.state.notes,
    }

    let selectedItems = []
    if (global.order.selectedItems) selectedItems = global.order.selectedItems
    else selectedItems = global.order.quotation.items

    let items = selectedItems.map(e=> ({ itemId: e.item.id, quantity: e.quantity }) )
    data.items = items

    this.setState({loading:true})
    if (isAllowedToEditQuotation(global.order)) {
      OrdersServices.editFixtureQuotation(global.order.id, data, (res)=>{
        delete global.order.selectedItems
        global.quotationItems = []
        global.quotationData = {}
        this.props.update()
      })
    }else {
      OrdersServices.editQuotation(global.order.id, data,(res)=>{
        delete global.order.selectedItems
        global.quotationItems = []
        global.quotationData = {}
        this.props.update()
      })
    }
    this.setState({loading:false})
  }

  render(){
    return(
      <div className='edit-quotation'>
        <p>Fixing price</p>
        <Input
          value={this.state.fixingPrice}
          onChange={(e)=>this.onChange(e, 'fixingPrice')}
          type="text"
          placeholder='Enter fixing price'  pattern="[0-9]*"
        />

        <p>Transportation price</p>
        <Input
          value={this.state.transportationPrice}
          onChange={(e)=>this.onChange(e,'transportationPrice')}
          type="text"
          placeholder='Enter transportation price'  pattern="[0-9]*"
        />

        <p>Working days</p>
        <Input
          value={this.state.workingDays}
          onChange={(e)=>this.onChange(e, 'workingDays')}
          type="text"
          placeholder='Enter working days'  pattern="[0-9]*"
        />
        <p>Notes</p>
        <Input
          value={this.state.notes}
          onChange={(e)=>this.setState({notes:e.target.value})}
          type="text"
          placeholder='Enter notes'  pattern="[0-9]*"
        />



        <Items noDivider />

        <div className='edit-quotation-btn' onClick={()=>this.editItems()}>
          <span>Add or Edit Items</span>
        </div>

        <Spinner loading={this.state.loading}/>
      </div>
    )
  }
}

export default {
  items: [
    {
      name: 'Orders',
      url: '/orders',
      icon: 'mdi mdi-home',
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'mdi mdi-chart-bar',
    },
    {
      name: 'Fixers',
      url: '/fixers',
      icon: 'mdi mdi-account',
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'mdi mdi-account-group',
    },
    {
      name: 'Complaints',
      url: '/complaints',
      icon: 'mdi mdi-message-text-outline',
    },
    {
      name: 'Promocodes',
      url: '/promocodes',
      icon: 'mdi mdi-tag-outline',
    },
    {
      name: 'Items',
      url: '/items',
      icon: 'mdi mdi-sitemap',
    },
    {
      name: 'Admins',
      url: '/admins',
      icon: 'mdi mdi-shield-account',
    },
    {
      name: 'Roles',
      url: '/roles',
      icon: 'mdi mdi-account-lock',
    },
    {
      name: 'User Manual',
      url: '/user-manual',
      icon: 'mdi mdi-television-guide',
    },



    // {
    //   name: 'Reports',
    //   url: '/home1',
    //   icon: 'mdi mdi-chart-bell-curve-cumulative',
    // },
    // {
    //   name: 'Complains',
    //   url: '/home4',
    //   icon: 'mdi mdi-message-text',
    // },
    // {
    //   name: 'Promocodes',
    //   url: '/home5',
    //   icon: 'mdi mdi-qrcode-scan',
    // },
    // {
    //   name: 'Notifications',
    //   url: '/home6',
    //   icon: 'mdi mdi-bell',
    // },
  ],
};

import React , { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import { ForbiddenImg } from './../../assets'

export default function Forbidden({}) {

  return(
    <div className='forbidden'>
      <img src={ForbiddenImg}/>
      <span className='note'>You don’t have permission to access</span>
      <span className='desc'>If you think you should access this  you could contact your system admin </span>
    </div>
  )
}

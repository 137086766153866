import React, {Component} from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { RolesServices } from './../../services'
import regex from './../../core/Regex'
import Switch from "react-switch";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions:[],
      value:'',
      loading:false
    }
  }
  componentDidMount(){
    this.getPermission()
    if (this.props.role != '') {
      this.setState({value:this.props.role.name})
      let arr = this.props.role.permissions
      if (arr.length != 0) {
        arr.map(e=> this.setState({['checked'+e.id]:true}))
      }
    }
  }
  getPermission(){
    this.setState({loading:true})
    RolesServices.getPermission(1, res=> this.setState({permissions: res.data, loading:false}))
  }

  isValid(action){
    let state = this.state
    let arr = []
    Object.keys(state).map((key)=> {
      key.includes('checked') && state[key] && arr.push( parseInt(key.replace('checked','')) )
    })

    if (!this.state.value.trim()) alert('Please, enter role name')
    else if (arr.length==0) alert('Please, assign atleast one role')
    else {
      let data = {
        name:this.state.value,
        displayName:this.state.value,
        permissions:arr
      }
      this.setState({loading:true})
      action == 'add' ?
      RolesServices.create(data, res=> this.setState({loading:false},()=>this.props.close()) )
      :
      RolesServices.edit(this.props.role.id, data, res=> this.setState({loading:false},()=>this.props.close()) )
    }
  }


  render(){
    return(
      <div className='permission'>

        <FInput
          placeholder='Role name'
          value={this.state.value}
          setValue={e=>this.setState({value: e})}
          borderBottom
          disabled={this.props.role != ''}
        />

        <div className='flex-row flex-wrap' >
          {this.state.permissions.map((e,k)=>(
            <div key={k} className='item'>
              <Switch
                onChange={(val)=>this.setState({['checked'+e.id]:val})}
                checked={this.state['checked'+e.id] ? this.state['checked'+e.id] : false}
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <span>{e.name}</span>
            </div>
          ))}
        </div>


        {this.props.role != ''?(
          <div className='edit-quotation-btn add-promo' onClick={()=>this.isValid('edit')}>
            <span>Edit role</span>
          </div>
        ):(
          <div className='edit-quotation-btn add-promo' onClick={()=>this.isValid('add')}>
            <span>Add role</span>
          </div>
        )}


        <Spinner loading={this.state.loading} />

      </div>
    )
  }
}

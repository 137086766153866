import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class RolesServices extends Component {

  static get(callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/roles`).then(callBack)
  }

  static getPermission(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/permissions`).then(callBack)
  }

  static create(data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/roles`, data).then(callBack)
  }

  static edit(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/roles/${id}/permissions`, data).then(callBack)
  }

}

import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class AuthServices extends Component {

  static login(data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/admins/login`, data).then(callBack)
  }

}

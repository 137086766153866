import React from 'react'
import { Bar } from 'react-chartjs-2'

export default function Chart({ data }) {
  return(
    <Bar
      data={data}
      options={{maintainAspectRatio: false,scales: { yAxes: [{ticks: {precision: 0, beginAtZero: true } }] }}}
      height={200}
    />
  )
}

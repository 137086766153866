import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { UncontrolledTooltip } from 'reactstrap'
import { ItemServices } from './../../services'
import { Child, FModal, AvailableFixer, Spinner, AddItem } from './../../components'

export default class Parent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChild:false
    }
  }
  addItem(){
    let data = this.AddItemRef.getData()
    if (data == 'noType') {
      alert('Select type')
    }else {
      if (data != 'stop') {
        if (data.type == 'parent') {
          if (!data.name.trim()) {
            alert('Enter parent name')
          }else {
            delete data.type
            data.isParent = 1
            data.parentId = this.props.item.id
            this.add(data)
          }
        }else{
          if (data != 'stop') {
            data.parentId = this.props.item.id
            this.add(data)
          }
        }
      }

    }
  }

  add(data){
    console.log('data',data);
    this.setState({loading:true})
    ItemServices.add(data, (res)=>{
      this.setState({loading:false, addPopup:false },()=>{
        if (this.state.showChild) this.ChildRef.pushItem(res.data)
      })
    })
  }

  render(){
    return(
      <div className='parent'>

        <div className='space-btw header'>
          <div className='wrapper'>
            <div className='space-btw title'  onClick={()=>this.setState({showChild:!this.state.showChild})}>
              <span>{this.props.item.name}</span>
              <span>{this.props.item.price} EGP</span>
              {/* <span>{this.props.item.price}  <i className={this.state.showChild?'mdi mdi-chevron-up':'mdi mdi-chevron-down'}/></span> */}
            </div>
            {this.state.showChild? <Child item={this.props.item} ref={ref=>this.ChildRef=ref}/> : null}
          </div>
          {this.props.noAdd?(
            <i className='mdi mdi-plus-circle-outline add' onClick={()=>this.setState({addPopup:true})}/>
          ):null}

        </div>

        <FModal
          title={`Add item for ${this.props.item.name}`}
          state={this.state.addPopup}
          content={<AddItem ref={ref=>this.AddItemRef=ref} item={this.props.item}/>}
          onClose={()=>this.setState({addPopup:false})}
          onSubmit={()=>this.addItem()}
          onSubmitText='Add'
        />

        <Spinner loading={this.state.loading} />
      </div>
    )
  }
}

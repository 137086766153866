import React, {Component} from 'react'
import { Input } from 'reactstrap'
import { isArabic } from './../../core/Helper'


export default class UpdateFixerStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arName:this.props.fixer.arName,
      enName:'',
      code:''
    }
  }

  onChange(e, type){
    this.setState({[type] : e.target.value})
  }

  validation(){
    if (!isArabic(this.state.arName)) {
      alert('Enter vaild arabic name.')
    }else if (isArabic(this.state.enName) || !this.state.enName.trim()) {
      alert('Enter vaild english name.')
    }else {
      this.props.set(this.props.fixer, {state:'active', name:this.state.enName, arName:this.state.arName, code:this.state.code})
    }
  }



  render(){
    return(
      <div className='edit-quotation'>
        <p>Arabic Name</p>
        <Input
          value={this.state.arName}
          onChange={(e)=>this.onChange(e, 'arName')}
          type="text" placeholder='Enter arabic name'
        />

        <p>English Name</p>
        <Input
          value={this.state.enName}
          onChange={(e)=>this.onChange(e,'enName')}
          type="text" placeholder='Enter english name'
        />

        <p>Code</p>
        <Input
          value={this.state.code}
          onChange={(e)=>this.onChange(e,'code')}
          type="text" placeholder='Enter english name'
        />
      </div>
    )
  }
}

import React, { Component } from 'react';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Logo } from './../../assets'
import { G } from './../../core/Global'
import { FixerServices, Services } from './../../services'
import { Spinner, Paginator, FModal, UpdateFixerStatus, AddNote, Notify, Filter } from './../../components'


class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      fixers:[],
      cities:[],
      query:'',
      selectCity:'',
      noData:false,
      page:1,
      pages:'',
      selectedFixer:''
    }
  }

  componentDidMount(){
    this.get(this.props.location.page ? this.props.location.page : this.state.page)
  }

  get(page){
    this.setState({loading:true})
    FixerServices.getAll(page, this.state.query, res => {
      this.setState({loading:false, fixers:res.data.fixers, page:res.data.pages.currentPages, pages:res.data.pages, noData:res.data.fixers.length==0})
    })
  }


  checkAction(fixer, state){
    if (state == 'active') fixer.name ? this.set(fixer.id, {state}) : this.setState({updatePopup:true,selectedFixer:fixer})
    else this.set(fixer.id, {state})
  }

  set(id, data){
    this.setState({loading:true})
    FixerServices.setSatatus(id, data, res => {
      console.log(res);
      this.setState({loading:false,updatePopup:false},()=>this.get(this.state.page))
    })
  }

  goFixer(fixer){
    global.fixer = fixer
    this.props.history.push({ pathname: `fixers/${fixer.id}`, page: this.state.page })
  }

  setQuery(query){
    this.setState({query:query.replace('?','&')}, ()=> this.get(1))
  }

  render() {
    return (
      <div className="fixers">

        <div className='space-btw' style={{paddingRight:'2%'}}>
          <h1>Fixers</h1>
        </div>
        {/* fixers list filter (searchTerm , states , blocked , city) */}
        <Filter byCities defaultCity byIsBlocked byState bySearchTerm stateFor='fixers' setQuery={(q)=>this.setQuery(q)} />
        {this.state.loading?null:
          !this.state.noData?(
            <>
            <Table>
              <thead>
                <tr>
                  {/* <th><i className='mdi mdi-information-outline'/></th> */}
                  <th className='col-title first'>id</th>
                  <th className='col-title'>fixer name</th>
                  <th className='col-title'>phone</th>
                  {/* <th className='col-title'>join date</th> */}
                  <th className='col-title'>orders</th>
                  <th className='col-title'>rate</th>
                  {/* <th className='col-title'>verified</th> */}
                  <th className='col-title'>status</th>
                  <th className='col-title'>is-blocked</th>
                  <th className='col-title'>city</th>
                  <th className='col-title'></th>
                </tr>
              </thead>
              <tbody>
                {this.state.fixers.map((e,k)=>(
                  <tr key={k} >
                    {/* <th className='col-value'><i className='mdi mdi-checkbox-blank-circle-outline'/></th> */}
                    <th className='col-value first' onClick={()=>this.goFixer(e)}>{e.id}</th>
                    <th className='col-value first' style={{paddingLeft:10}} onClick={()=>this.goFixer(e)}>{e.arName?e.arName:e.name}</th>
                    <th className='col-value'>{e.phone}</th>
                    {/* <th className='col-value'>{e.joinDate}</th> */}
                    <th className='col-value'>{e.ordersCount}</th>
                    <th className='col-value'>{e.numberOfRates}</th>
                    <th className='col-value'>
                      {e.state}
                    </th>
                    <th className='col-value'>{e.isBlocked?'Blocked':'Avaiable'}</th>
                    <th className='col-value'>{e.city.enName}</th>
                    <th className='col-value'>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={this.state.menu} >
                          <i className='mdi mdi-dots-vertical'/>
                        </DropdownToggle>
                        <DropdownMenu>

                          <div onClick={()=>this.setState({user:e, addNotePopup:true})}>Add Note</div>
                          {/* <div >Change status</div> */}
                          {e.state =="active"?(
                            <div   onClick={()=>this.checkAction(e, 'in-active')}>In-active fixer</div>
                          ):(
                            <div onClick={()=>this.checkAction(e, 'active')} >Active fixer</div>
                          )}

                          <div onClick={()=>this.setState({user:e,notifyType:'notification',notifiyPopup:true})}>Send push notification</div>
                          <div onClick={()=>this.setState({user:e,notifyType:'sms',notifiyPopup:true})}>Send SMS</div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </th>
                  </tr>
                ))}

              </tbody>
            </Table>
            {this.state.pages==''? null : <Paginator pages={this.state.pages} get={(page)=>this.setState({page},()=>this.get(page)) }/> }
          </>
        ):(
          <div className='no-data'>
            <p>😕</p>
            <span>There is no fixers, try change filter options.</span>
          </div>
        )}

        <FModal
          title='Update fixer status'
          state={this.state.updatePopup}
          content={<UpdateFixerStatus
            ref={(ref)=> this.updateFixerRef = ref}
            fixer={this.state.selectedFixer}
            set={(fixer, data)=>this.set(fixer.id, data)}
          />}
          onClose={()=>this.setState({updatePopup:false})}
          onSubmit={()=>this.updateFixerRef.validation()}
          onSubmitText='Done'
        />

        <FModal
          title='Add Note'
          state={this.state.addNotePopup}
          content={<AddNote user={this.state.user} close={()=>this.setState({addNotePopup:false,},()=> this.get(this.state.page))}/>}
          onClose={()=>this.setState({addNotePopup:false})}
          noSubmitBtn
        />

        <FModal
          title={`Send ${this.state.notifyType}`}
          state={this.state.notifiyPopup}
          content={<Notify user={this.state.user} type={this.state.notifyType} close={()=>this.setState({notifiyPopup:false})}/>}
          onClose={()=>this.setState({notifiyPopup:false})}
          noSubmitBtn
        />

        <Spinner loading={this.state.loading}/>
      </div>
    )
  }
}

export default withRouter(List)

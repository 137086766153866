import React, { useEffect, useState, Fragment } from 'react'
import { OrdersServices } from './../../../services'
import { FSwitcher } from './../../../components'

export default function Cancel({update}) {

  const [data, setDate] = useState(null)
  const [selectedReason, setSelectedReason] = useState(null)

  useEffect(()=> getReasons() ,[])

  const cancel = () => {
    if (!selectedReason) alert('Select reason')
    else{
      // console.log(selectedReason);return
      let id = data.find(r => selectedReason == r.enName).id
      // console.log(id); return
      OrdersServices.cancel(global.order.id, {reason:id}, res=> {
        update()
      })
    }

  }

  const getReasons = () => {
    OrdersServices.getReasons('?relatedTo=order_cancel', res=> setDate(res.data) )
  }
  const getIds = (select, res) =>{
    let arr = []
    return select.map(s => arr.push(res.find(r => s == r.enName).id) )
  }

  return(
    data && (
      <div className='rooms'>

        <FSwitcher
          title='Select reason'
          array={data.map(e=> e.enName)}
          select={setSelectedReason}
        />

        <div className='edit-quotation-btn add-promo' onClick={()=>cancel()}>
          <span>Cancel</span>
        </div>

      </div>
    )
  )
}

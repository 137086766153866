import { G } from './Global'
import axios from 'axios'

let localAccessToken = '', isRefreshing = false, failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  failedQueue = [];
}
axios.interceptors.request.use(async request => {
  if (localStorage.getItem('AmalUserData'))
  localAccessToken = JSON.parse(localStorage.getItem('AmalUserData')).accessToken

  request.headers.Accept = 'application/json';
  request.headers.Authorization = `Bearer ${localAccessToken}`;
  return request
})

axios.interceptors.response.use(response => {
  console.log(response);
return response.data
},
error => {
  const originalRequest = error.config;
  if (error.response.status === 403) {
     window.goTo ? window.goTo('/forbidden') : window.open(`${G.url}forbidden`,"_self")
  }
  if (error.response.status === 400) {
    return error.response.data
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    const isLogged = localStorage.getItem('AmalLogged')
    if (!isLogged) {
      window.goLogin()
    }
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = window.localStorage.getItem('refreshToken');
    if (refreshToken) {
      return new Promise(function (resolve, reject) {
        axios.post(`${G.baseURL}token/refresh`, { refreshToken: JSON.parse(localStorage.getItem('AmalUserData')).refreshToken })
        .then((response) => {
          window.localStorage.setItem('AmalUserData',JSON.stringify(response.data))
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
          originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;
          processQueue(null, response.data.accessToken);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => { isRefreshing = false })
      })
    }

  }
  return Promise.reject(error);
})

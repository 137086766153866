import React, {Component} from 'react'
import {OrdersServices} from './../../../services'
import { Table, } from 'reactstrap'
import { Spinner } from './../../../components'

export default class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs:[]
    }
  }

  componentDidMount(){
    this.getHistory()
  }

  getHistory(){
    this.setState({loading:true})
    OrdersServices.getHistory(global.order.id, res=>{
      this.setState({logs:res.data, loading:false})
    })
  }

  render(){
    return(
      <div className='logs'>
        <Table>
          <thead>
            <tr>
              <th className='col-title'>Maker</th>
              <th className='col-title'>Action</th>
              <th className='col-title' width='20%'>Date</th>
            </tr>
          </thead>
          <tbody>
            {this.state.logs.map(e=>(
              <tr key={e.id}>
                <th className='col-value'>{e.actionMakerType.type}</th>
                <th className='col-value'>{e.description}</th>
                <th className='col-value'>{e.createdAt}</th>
              </tr>
            ))}
          </tbody>
        </Table>

        <Spinner loading={this.state.loading}/>
      </div>
    )
  }
}

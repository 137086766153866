import React, {Component} from 'react'
import State from './State'
import Details from './Details'
import { FModal, ChargeWallet, Spinner, Notify, Notes } from './../../../components'
import { Services } from './../../../services'

export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet:''
    }
  }
  componentDidMount(){
    Services.getBalance(global.customer.id, res=> this.setState({wallet: res.data}))
  }

  render(){
    return(
      <div className='space-btw invoice' style={{width:'100%'}}>

        <div style={{width:'100%', height:window.innerHeight-55, overflow:'scroll'}}>

          <Details />

          <div className='center actions'>
            <div onClick={()=>this.setState({notesPopup:true})}>
              <span>Notes</span>
            </div>
          </div>

        </div>


        <div className='right-hand'>

          <State update={()=>this.props.update()}/>

          <div className='fixer-action'>
            <div onClick={()=>this.setState({notifyType:'notification',notifiyPopup:true})}>
              <span>Send push notification</span>
            </div>
            <div onClick={()=>this.setState({notifyType:'sms',notifiyPopup:true})}>
              <span>Send SMS</span>
            </div>
          </div>

          <div className='invoice'>

            <span className='parent'>Wallet</span>

            <div className='center' style={{margin:'5px 20px 30px'}}>
              <span className='parent total'>{this.state.wallet && this.state.wallet.toFixed(2)} EGP</span>
            </div>

            {/* <div className='re-price' onClick={()=>this.setState({chargePopup:true})}>
              <span>REACHAGE WALLET</span>
            </div> */}

          </div>

        </div>

        <FModal
          title='Charge wallet'
          state={this.state.chargePopup}
          content={<ChargeWallet close={()=>this.setState({chargePopup:false},()=> this.props.update())}/>}
          onClose={()=>this.setState({chargePopup:false})}
          noSubmitBtn
        />

        <FModal
          title={`Send ${this.state.notifyType}`}
          state={this.state.notifiyPopup}
          content={<Notify user={global.customer} type={this.state.notifyType} close={()=>this.setState({notifiyPopup:false})}/>}
          onClose={()=>this.setState({notifiyPopup:false})}
          noSubmitBtn
        />

        <FModal
          title='Notes'
          state={this.state.notesPopup}
          content={<Notes user={global.customer} close={()=>this.setState({notesPopup:false})}/>}
          onClose={()=>this.setState({notesPopup:false})}
          noSubmitBtn
          width={'70%'}
        />

      </div>
    )
  }
}

import React, {Component} from 'react'


export default class Fixer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <div className='card space-btw' style={{padding:'0% 30% 0% 7%'}}>
        <div style={{alignSelf:'center'}}>
          <span className='title'>Fixer name</span>
          <span className='title-value'>{global.order.fixer.enName?global.order.fixer.enName:global.order.fixer.arName}</span>
        </div>
        <div style={{alignSelf:'center'}}>
          <span className='title'>Fixer phone</span>
          <span className='title-value'>{global.order.fixer.phone}</span>
        </div>
      </div>
    )
  }
}

import React , { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import { Img1, Img2, Img3, Img4, Img5, Img0 } from './../../assets'

export default function HowToUse({}) {

  return(
    <div className='user-manual'>
      <h1 style={{marginBottom:60}}>How to use Sabbak</h1>
      <div className='space-btw use-card'>
        <div>
          <h3>Choose Service</h3>
          <div className='under-line'/>
          <h6>- Between Assay, Maintenance and Emergency pick a service. </h6>
        </div>
        <img src={Img0} />
      </div>


      <div className='space-btw use-card'>
        <div>
          <h3>Enter Order details</h3>
          <div className='under-line'/>
          <h6>- Tell us what you need to fix by </h6>
          <ul>
            <li>Select item</li>
            <li>Enter description </li>
            <li>Pick date and solt </li>
            <li>Choose address </li>
            <li>Finally select a fixer </li>
            <li>Book the order</li>
          </ul>
        </div>
        <img src={Img1} />
      </div>


      <div className='space-btw use-card'>
        <div>
          <h3>Track order</h3>
          <div className='under-line'/>
          <h6>- You can track and see each order details here you can trach </h6>
          <ul>
            <li>Order status</li>
            <li>Log </li>
            <li>Invoice </li>
            <li>Order details </li>
          </ul>
        </div>
        <img src={Img3} />
      </div>


      <div className='space-btw use-card'>
        <div>
          <h3>List orders</h3>
          <div className='under-line'/>
          <h6>- You can view all orders here </h6>
        </div>
        <img src={Img4} />
      </div>


      <div className='space-btw use-card'>
        <div>
          <h3>Set your settings</h3>
          <div className='under-line'/>
          <ul>
            <li>Choose language</li>
            <li>View all complaints</li>
            <li>Customer help </li>
            <li>View your wallet </li>
          </ul>
        </div>
        <img src={Img5} />
      </div>



      <div style={{height:70}}/>

    </div>
  )
}

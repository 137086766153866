import React, {Component} from 'react'
import { FixerServices } from './../../services'

export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }



  render(){
    return(
      <div className='state'>
        {/* <div className='center-title'>Performance</div>
          <div className='space-btw'>
            <span className='title'>Current uses times</span>
            <span className='title '>102</span>
          </div>

          <div className='space-btw'>
            <span className='title'>No# of users</span>
            <span className='title '>150</span>
          </div>

          <div className='space-btw'>
            <span className='title'>No# of orders</span>
            <span className='title '>15</span>
          </div> */}

      </div>

    )
  }
}

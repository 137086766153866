import React, {  useState, useEffect } from 'react'
import { ReportsFilter, Chart } from './../../components'
import { Services } from './../../services'

export default function DoneOrders({cities, services}) {

  const [data, setData] = useState(null)
  const [query, setQuery] = useState('')

  useEffect(()=> getReport(query) ,[query])

  const getReport = (query) => {
    let qu = query? query+'&states[0]=Done&states[1]=Completed':'?states[0]=Done&states[1]=Completed'
    Services.getReport(qu, 'orders', res => {
      let data = {
        labels:res.data.map(e=> e.createdAt),
        datasets: [
          {
            label: '# of Done Orders',
            data:res.data.map(e=> e.count),
            backgroundColor:'#1a3b73',
            borderWidth: 1,
          },
        ],
      }
      setData(data)
    })
  }

  return(
    <div className="single">

      <div className='space-btw'>
        <span className='report-title'>Done orders per day</span>

        <ReportsFilter cities={cities} services={services} setQuery={setQuery}/>

      </div>

      {data && <Chart data={data}/> }

    </div>
  )
}

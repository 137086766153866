import React , { useState , useEffect} from 'react'
import { Input } from 'reactstrap';
import { CompalintsServices } from './../../services'

export default function MessageBox({ chat, push, updateChat }) {

  const [reply, setReply] = useState('')

  const isEnter = (event) => { event.keyCode === 13 &&  send()}

  const send = () => {
    if (reply.trim())
    CompalintsServices.send(chat.id, {reply}, res => {
      res.message == 'success' && setReply('')
      // push(res.data)
      push({ me: true, reply }, res.data.state)
      updateChat(res.data)
    })

  }

  return(
    <div className='messages-box space-btw'>
      <Input
        value={reply}
        onChange={e=> setReply(e.target.value)}
        type="text"
        placeholder={chat.state == 'closed'?'Change statue to in-progress in order ot sending reply':"Send reply"}
        onKeyDown={(e) => isEnter(e) }
        disabled={chat.state == 'closed'}
      />
      {chat.state != 'closed'?
      <i className='mdi mdi-send' onClick={send}/>
      :
      <i className='mdi mdi-block-helper' />
    }

    </div>
  )
}

import React, {Component} from 'react'
import {Modal} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay';

export default class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <Modal fade={this.props.fade} isOpen={this.props.loading} className='loading'>
        <LoadingOverlay active={this.props.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
      </Modal>
    )
  }
}

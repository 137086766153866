import React, {Component} from 'react'
import State from './State'
import Add from './Add'


export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render(){
    return(
      <div className='space-btw invoice' style={{width:'100%'}}>

        <div style={{width:'100%', height:window.innerHeight-55, overflow:'scroll'}}>

          <Add goBack={()=>this.props.goBack()}/>

        </div>


        <div className='right-hand'>

          <State update={()=>this.props.update()}/>

        </div>

      </div>
    )
  }
}

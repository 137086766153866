import React, { Component } from 'react';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Logo } from './../../assets'
import { G } from './../../core/Global'
import { PromoServices } from './../../services'
import { Spinner, FModal, AddItem, Parent, Paginator } from './../../components'

let page = 1

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      promocodes:[],
      noPromo:false,
      pages:'',
    }
  }

  componentDidMount(){
    page = 1
    this.get(page)
  }
  componentWillUnmount(){
  }

  get(page){
    this.setState({loading:true})
    let query = `?page=${page}`
    PromoServices.get(query, (res)=>{
      // this.setState(pS=>({loading:false, promocodes:pS.promocodes.concat(res.data.data.promoCodes), pages:res.data.data.pages, noPromo:pS.promocodes.concat(res.data.data.promoCodes).length==0 }))
      this.setState({loading:false, promocodes:res.data.promoCodes, pages:res.data.pages, noPromo:res.data.promoCodes.length==0})
    })
  }

  goAdd(){
    this.props.history.push(`promocodes/add`)
  }


  render() {
    return (
      <div className="flex-row main-items promo">
        <div className="items">
          <div className='space-btw title'>
            <h1>Promo codes</h1>
            <div className='f-btn btn-add' onClick={()=>this.goAdd()}>
              <span>Add Promo</span>
            </div>
          </div>

          {this.state.loading?null:
            !this.state.noPromo?(
              <>
              <Table>
                <thead>
                  <tr>
                    <th className='col-title first'>id</th>
                    <th className='col-title first'>code</th>
                    <th className='col-title'>amount</th>
                    <th className='col-title'>uses</th>
                    <th className='col-title'>max use</th>
                    <th className='col-title'>type</th>
                    <th className='col-title'>applied from</th>
                    <th className='col-title'>applied to</th>
                    <th className='col-title'>cities</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.promocodes.map((e,k)=>(
                    <tr key={k} >
                      <th className='col-value first'>{e.id} </th>
                      <th className='col-value first'>{e.code} </th>
                      <th className='col-value'>{e.amount}</th>
                      <th className='col-value'>{e.numberOfUsesPerUser}</th>
                      <th className='col-value'>{e.maxAmountOfDiscount}</th>
                      <th className='col-value'>{e.type}</th>
                      <th className='col-value'>{e.validFrom}</th>
                      <th className='col-value'>{e.validTo}</th>
                      <th key={k} className='col-value'>{e.cities.map((i,k)=>(i.enName+', ') )}</th>
                    </tr>
                  ))}
                </tbody>
              </Table>
            {this.state.pages==''? null : <Paginator pages={this.state.pages} get={(page)=>this.get(page)}/> }
            </>
          ):(
            <div className='no-data'>
              <p>😕</p>
              <span>There is no promocodes</span>
            </div>
          )}

          <Spinner loading={this.state.loading} />
        </div>

      </div>
    )
  }
}

export default withRouter(List)

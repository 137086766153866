import React , { useState, useEffect } from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { Services } from './../../services'
import regex from './../../core/Regex'


export default function Notify({ user, type, close }) {

  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)


  const validate = () =>{
    if (!value.trim()) alert('Enter value')
    else {
      const data = {
        body:value,
        type
      }
      setLoading(true)
      Services.notify(user.id, data , res => {
        setLoading(false)
        res.message=="success" ? close() : alert(res.message)
      })
    }
  }

  return(
    <div className=''>

        <FInput
          placeholder='Content goes here'
          value={value}
          setValue={setValue}
          type="textarea"
          style={{marginBottom:-20}}
        />


      <div className='edit-quotation-btn add-promo' onClick={()=>validate()}>
        <span>Send</span>
      </div>

      <Spinner loading={loading} />

    </div>
  )
}

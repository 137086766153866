import React, {Component} from 'react'
import Items from './Items'
import moment from 'moment'

export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time:''
    }
  }

  componentDidMount(){
    let time = ''
    let duration = moment.duration(moment(global.order.interval.endedAt).diff(global.order.interval.startedAt));
    time = moment.utc( (duration._milliseconds) ).locale('en').format('HH:mm:ss')
    this.setState({time})
  }

  render(){
    return(
      <div className='invoice-details'>

        {this.props.withItems? <Items /> : null }

        {this.props.forRooms?null:(
          <>
          <div className='space-btw' style={{marginTop:15, padding:'0px 12px'}}>
            <span className='parent'>Assay time</span>
            <span className='parent '>{this.state.time}</span>
          </div>





          {global.order.invoice?this.props.noTotal?null:(
            <>

            <div className='space-btw' style={{marginTop:15, padding:'0px 12px'}}>
              <span className='parent'>Promo Amount</span>
              <span className='parent '>- {global.order.invoice.promoAmount} EGP</span>
            </div>

            <div className='space-btw' style={{marginTop:15, padding:'0px 12px'}}>
              <span className='parent'>Collected Amount</span>
              <span className='parent '>{global.order.invoice.collectedAmount} EGP</span>
            </div>
            <div className='divider'/>
            <div className='space-btw' style={{marginTop:5}}>
              <span className='parent' style={{paddingLeft:this.props.forReprice?12:0}}>Total</span>
              <span className='parent total'>{global.order.invoice.totalPrice} EGP</span>
            </div>
            </>

          ):null}
          </>
        )}


      </div>

    )
  }
}

import React, {Component} from 'react'
import { FixerServices } from './../../../services'
import { Spinner, FModal, UpdateFixerStatus } from './../../../components'

export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocked: global.customer.isBlocked,
    }
  }

  block(){
    this.setState({loading:true})
    FixerServices.block(global.customer.id, res => {
      this.setState({loading:false, isBlocked:!this.state.isBlocked })
    })
  }

  render(){
    return(
      <div className='state'>
        <div>
          <div>
            <span className='title'>Is blocked</span>
            <div className='space-btw toggle'>
              <div className={this.state.isBlocked?'flex-row':'flex-row active'} onClick={()=>this.state.isBlocked?this.block():null}>
                <i className={this.state.isBlocked?'mdi mdi-circle-outline':'mdi mdi-circle'}/>
                <span className='title'> Un blocked</span>
              </div>
              <div className={!this.state.isBlocked?'flex-row':'flex-row active'} onClick={()=>!this.state.isBlocked?this.block():null}>
                <i className={!this.state.isBlocked?'mdi mdi-circle-outline':'mdi mdi-circle'}/>
                <span className='title'> Blocked</span>
              </div>
            </div>
          </div>

        </div>

        <Spinner loading={this.state.loading}/>
      </div>

    )
  }
}

import React , { useState } from 'react'


export default function List({ data, next, noMore, select }) {

  const [active, setActive] = useState(null)

  const onSelect = (e) => {
    select(e)
    setActive(e)
  }

  return(
    <div className='list'>
      <div style={{height:window.innerHeight-125, overflow:'scroll'}}>
        {data.map((e,k)=>(
          <div key={k} className={active==e?'active item':'item'} onClick={()=>onSelect(e)}>
            <span>Complain #{e.id}</span>
            <span>{e.description}</span>
          </div>
        ))}
        {!noMore && <span className='more' onClick={next}>More</span>}
      </div>



      {/* <div style={{backgroundColor:'#1a3b73', height:(window.innerHeight-(window.innerHeight-50))}}>
        <span style={{display:'block', textAligh:'center', color:'#fff'}}>omarw</span>
      </div> */}

    </div>
  )
}

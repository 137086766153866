export { default as Logo } from './images/amal.png'
export { default as Fixer } from './images/fixer.png'
export { default as ForbiddenImg } from './images/forbidden.png'
export { default as Customer } from './images/customer.png'
export { default as Img1 } from './images/1.png'
export { default as Img2 } from './images/2.png'
export { default as Img3 } from './images/3.png'
export { default as Img4 } from './images/4.png'
export { default as Img5 } from './images/5.png'
export { default as Img0 } from './images/0.png'


export { default as style } from './styles/style.scss'
export { default as mediaQuery } from './styles/mediaQuery.scss'

export { default as chatPlaceholder } from './gifs/chat2.gif'

export { default as sound1 } from './sounds/new-order.mp3'

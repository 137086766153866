import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { UncontrolledTooltip } from 'reactstrap'
import { OrdersServices } from './../../services'
import { MapMarker, FModal, AvailableFixer, Spinner, Parent } from './../../components'
import { ItemServices } from './../../services'
import { union, uniq } from 'underscore'

let page = 1

export default class Child extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items:[],
      showChild:false,
      noChild:false
    }
    global.idEditItem = global.idEditItem ? global.idEditItem : false
  }

  componentDidMount(){
    page = 1
    this.get(page)

  }

  get(page){
    this.setState({loading:true, noChild:false})
    let query = `?parentId=${this.props.item.id}&page=${page}`
    ItemServices.getAll(query, (res)=>{
      this.setState(pS=>({loading:false, items:uniq(pS.items.concat(res.data),'id'), noChild:res.data.length==0 }) )
    })
  }

  pushItem(item){
    console.log('inside child pushing item', item);
    this.setState(pS=> ({ items:pS.items.concat(item), showChild:false }) )
  }

  getMore(){
    page+=1
    this.get(page)
  }

  render(){
    return(
      <div >
        {this.state.items.map(item=>(
          item.isParent? <Parent key={item.id} item={item} />:(
            <div className={item.isParent?'space-btw child-parent':'space-btw child'} key={item.id}
              onClick={()=>global.idEditItem?window.pushItem(item):null}>
              <span style={{direction:'rtl'}}>{item.name}</span>
              <span>{item.price} EGP</span>
            </div>
          )
        )
      )}

        {this.state.noChild?(
          <h6 className='no-item'>{this.state.items.length==0?'No items.':'No more.'}</h6>
        ):(
          <h6 className='get-more' onClick={()=>this.getMore()}>Get more </h6>
        )}

        <Spinner loading={this.state.loading} />
      </div>
    )
  }
}

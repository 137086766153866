import React, { Component } from 'react';
import { Button, Container } from 'reactstrap'
import { FixerServices } from './../../services'
import { Spinner, PromoSummery } from './../../components'


export default class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
    // this.get(global.fixer? global.fixer.id : this.props.match.params.id, true)
  }
  goBack(){
    this.props.history.push('/promocodes')
  }
  get(id, loading){
    this.setState({loading})
    FixerServices.get(id, res=>{
      global.fixer = res.data
      this.setState({loading:false})
    })
  }

  render() {
    return (
      <div style={{backgroundColor:'#fafafa'}}>
        <div className='flex-row single-order promo'>

          <div className='back' onClick={()=>this.goBack()}>
            <i className='mdi mdi-chevron-left' style={{fontSize:30}}/>
          </div>

          <PromoSummery  goBack={()=>this.props.history.push('/promocodes')} />

        </div>

        <Spinner loading={this.state.loading}/>
      </div>
    )
  }
}

import React, {Component} from 'react'
import {Customer} from './../../../assets'
import moment from 'moment'

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <div className='card'>

        <img src={Customer} style={{width:120, height:120, alignSelf:'center', margin:'20px 0px 40px 0px'}}/>

        <div className='info' >
          <div>

            <div>
              <span className='title'>Customer name</span>
              <span className='title-value'>{global.customer.name}</span>
            </div>

            {/* <div>
              <span className='title'>City</span>
              <span className='title-value'>{global.customer.city.enName}</span>
            </div> */}

            <div>
              <span className='title'>Orders</span>
              <span className='title-value'>{global.customer.ordersCount}</span>
            </div>

            <div>
              <span className='title'>Gender</span>
              <span className='title-value'>{global.customer.gender}</span>
            </div>

          </div>

          <div >

            <div>
              <span className='title'>Phone</span>
              <span className='title-value'>{global.customer.phone}</span>
            </div>

            <div>
              <span className='title'>Join date</span>
              <span className='title-value'>{moment(global.customer.createdAt).format('YYYY/MM/DD')}</span>
            </div>



          </div>

        </div>

      </div>
    )
  }
}

import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class FixerServices extends Component {

  static getAll(page=1, query='', callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/fixers?page=${page}${query}`).then(callBack)
  }
  static get(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/fixers/${id}`).then(callBack)
  }

  static getAvailable(page, id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/orders/${id}/fixers?page=${page}`).then(callBack)
  }

  static setSatatus(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/fixers/${id}/state`, data).then(callBack)
  }

  static block(id, callBack) {
    Adaptor.patch(`${G.baseURL}admin-panel/users/${id}/block`).then(callBack)
  }

  static chargeWallet(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/users/${id}/chargeWallet`, data).then(callBack)
  }

}

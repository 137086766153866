import React, {Component, Fragment} from 'react'
import Invoice from './Invoice'
import { OrdersServices } from './../../../services'
import { Spinner, FModal } from './../../../components'
import EditQuotation from './EditQuotation'
import {isAllowedToEditQuotation, canEditQuotation} from './../../../core/Helper'

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    }
  }

  componentDidMount(){
    this.setState({editPopup:"selectedItems" in global.order})
  }

  review(){
    this.setState({loading:true})
    OrdersServices.reviewQuotation(global.order.quotation.id, (res)=>{
      this.setState({loading:false})
      this.props.update()
    })
  }


  render(){
    return(
      <div className='card invoice'>
        <span className='parent'>Quotation details</span>

        <Invoice noTotal withItems/>


        <div className='space-btw days'>
          <span className='parent'>Fixing price</span>
          <span className='parent'>{global.order.quotation.fixingPrice.toFixed(2)} EGP</span>
        </div>
        <div className='divider'/>


        <div className='space-btw days'>
          <span className='parent'>Transportation price</span>
          <span className='parent'>{global.order.quotation.transportationPrice.toFixed(2)} EGP</span>
        </div>
        <div className='divider'/>

        <div className='space-btw days' style={{marginTop:15}}>
          <span className='parent'>Total</span>
          <span className='parent total'>{global.order.quotation.totalPrice.toFixed(2)} EGP</span>
        </div>
        <div className='divider'/>

        <div className='days'>
          <span className='child'>Working days</span>
          <span className='parent'>{global.order.quotation.workingDays} days</span>
        </div>



        {global.order.quotation.notes?(
          <Fragment>
            <div className='divider'/>
            <span className='child' style={{margin:'10px 0px 10px 0px',paddingLeft:'2%'}}>{global.order.quotation.notes}</span>
          </Fragment>
        ):null}


        <div className='space-rnd quotation'>

          {global.order.quotation.state == 'pending_review'?(
            <div onClick={()=>this.review()}>
              <span>CONFIRM</span>
            </div>
          ):<div style={{display:'none'}}/>}

          {/* {isAllowedToEditQuotation(global.order) ?(
              <div onClick={()=>this.setState({editPopup:true})}>
                <span>EDIT</span>
              </div>
            ):global.order.quotation.state != 'converted_to_order'?(
            <div onClick={()=>this.setState({editPopup:true})}>
              <span>EDIT</span>
            </div>
          ):<div style={{display:'none'}}/>} */}


          {canEditQuotation(global.order) ?(
              <div onClick={()=>this.setState({editPopup:true})}>
                <span>EDIT</span>
              </div>
            ):<div style={{display:'none'}}/>}
        </div>


        <FModal
          title='Edit quotation'
          state={this.state.editPopup}
          content={<EditQuotation ref={(ref)=> this.EditQuotationRef = ref} update={()=>this.props.update()}/>}
          onClose={()=>this.setState({editPopup:false})}
          onSubmit={()=>this.EditQuotationRef.edit()}
          onSubmitText='Done'
        />

        <Spinner loading={this.state.loading}/>
      </div>
    )
  }
}

import React , { useState, useEffect, useRef } from 'react'
import { CompalintsServices } from './../../services'
import { sound1 } from './../../assets'
import Header from './Header'
import MessageBox from './MessageBox'
import {useForceUpdate} from './../../core/Helper'
import moment from 'moment'

export default function Chat({ chat, goOrder, goUser }) {

  const [data, setData] = useState({})
  const msgRef = useRef(null)
  const forceUpdate = useForceUpdate()

  useEffect(()=> getChat() , [chat])

  const getChat = () => {
    CompalintsServices.single(chat.id, res=> {
      setData(res.data)
      msgRef.current.scrollTo({top: msgRef.current.scrollHeight, behavior: 'smooth'})
    })
  }


  const push = (msg, state) => {
    let newData = data
    newData.complaintActions.push(msg)
    chat.state = state
    setData(newData)
    forceUpdate()
    msgRef.current.scrollTo({top: msgRef.current.scrollHeight, behavior: 'smooth'})
  }
  const updateChat = (newChat) =>{
    chat = newChat
    forceUpdate()
  }

  window.newMsg = (payload) => {
    if (window.location.href.includes('/complaints') && chat.id == JSON.parse(payload.data.complaintMessage).complaintId) {
      let audio = new Audio(sound1)
      audio.play()
      Object.keys(payload.data).map((key, index)=> {
        if (payload.data[key] == 'complaintMessage') {
          data.complaintActions.push(JSON.parse(payload.data.complaintMessage).reply)
          setData(data)
          forceUpdate()
          msgRef.current.scrollTo({top: msgRef.current.scrollHeight, behavior: 'smooth'})
        }
      })
    }
  }

  const renderMessage = (message, isSender, k) => {
    return(
      <div >
        <div className='message' key={k} style={{justifyContent:isSender?'flex-end':'flex-start'}}>
          <div style={{backgroundColor:isSender?'#52c3c2':'#f0f0f1',paddingHorizontal:15,}}>
            <span style={{color:isSender?'#fff':'#999a9c'}}> {message.reply}</span>
          </div>
        </div>
        <span className='message-time' style={{justifyContent:isSender?'flex-end':'flex-start'}}>{moment(message.createdAt).calendar()}</span>
      </div>
    )
  }

  return(
    <div className='messages' style={{height:window.innerHeight-125}}>
      <Header chat={chat} goOrder={goOrder} updateChat={updateChat} goUser={goUser}/>

      <div className='messages-list' style={{height:window.innerHeight-235}} ref={msgRef}>
        {data.complaintActions &&
          data.complaintActions.map((e,k)=> renderMessage(e, e.me, k) )
        }
      </div>

      <MessageBox chat={chat} push={push} updateChat={updateChat}/>
    </div>
  )
}

import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { Input } from 'reactstrap'
import { OrdersServices } from './../../services'
import { MapMarker, FModal, AvailableFixer, Spinner, AddChild } from './../../components'
import moment from 'moment'

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select:'',
      name:'',
    }
  }

  getData(){
    let data = {}
    if ( this.props.item && this.state.select == '') {
      return 'noType'
    }else {
      if (this.props.item && this.state.select=='child') {
        if (this.AddChildRef.getData() != 'stop') {
          data = this.AddChildRef.getData()
          data.isParent = false
          return data
        }else {
          return 'stop'
        }
      }else {
        data.name = this.state.name
        data.type = 'parent'
        return data
      }
      console.log('data',data);

    }
  }


  render(){
    return(
      <div className='item'>
        {this.props.item?(
          <div>
            <div className='header padding-mob space-btw' style={{padding:'0px 40% 5% 0px'}}>
              <div className='flex-row select' onClick={()=> this.setState({select:'parent'})}>
                <i className={this.state.select=='parent'?'mdi mdi-checkbox-marked-circle active':'mdi mdi-checkbox-blank-circle-outline'}/>
                <span> Parent </span>
              </div>
              <div className='flex-row select' onClick={()=> this.setState({select:'child'})}>
                <i className={this.state.select=='child'?'mdi mdi-checkbox-marked-circle active':'mdi mdi-checkbox-blank-circle-outline'}/>
                <span> Child </span>
              </div>
            </div>
            {this.state.select=='parent'?(
              <Input
                  value={this.state.name}
                  onChange={(e)=>this.setState({name:e.target.value})}
                  type="text"
                  placeholder="Enter parent name"
                />
              ):this.state.select=='child'?(
                <AddChild ref={(ref)=>this.AddChildRef =ref}/>
              ):null}
          </div>
        ):(
          <div className='header'>
            <Input
              value={this.state.name}
              onChange={(e)=>this.setState({name:e.target.value})}
              type="text"
              placeholder="Enter parent name"
            />
          </div>
        )}
      </div>
    )
  }
}

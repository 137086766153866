import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAxcgeVS3uNnBA1CV9aOi6r_7J40MWAXaU",
  authDomain: "sabbak-30573.firebaseapp.com",
  projectId: "sabbak-30573",
  storageBucket: "sabbak-30573.appspot.com",
  messagingSenderId: "736491149244",
  appId: "1:736491149244:web:cc16a284b09dcffd9a7db9",
  measurementId: "G-HQLR4HE6TJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

console.log('app',app);























// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'
// import { G } from './Global'
//
// window.Pusher = require('pusher-js');
// // const pusher = new Pusher(G.APP_KEY, {
// //   cluster: G.APP_CLUSTER,
// //   httpHost:'alamal.fixawy.net',
// //   wsHost:'alamal.fixawy.net',
// //   httpPort: 6001,
// //   httpsPort: 6001,
// //   // enabledTransports: ['ws', 'wss'],
// //   // useTLS:false
// //   httpHost: "alamal.fixawy.net"
// // });
// // // console.log('pusher',pusher);
// // console.log('window.Pusher',window.Pusher);
//
// window.Echo = new Echo({
//   // broadcaster: 'pusher',
//   // key: 'your-pusher-channels-key',
//   // client: client
//   broadcaster: 'pusher',
//   key: G.APP_KEY,
//   cluster: G.APP_CLUSTER,
//   wsHost: window.location.hostname,
//   // httpHost: "alamal.fixawy.net",
//   wsPort: 6001,
//   wssPort: 6001,
//   // protocol: "http",
//   // disableStats: true,
//   encrypted: false,
//   disableStats: false,
//   // forceTLS:true,
//   // enabledTransports: ['ws', 'wss'],
// });
// // console.log(window.Echo);
// //
// //
// console.log('window.Echo', window.Echo);
// window.Echo.channel('order').listen('message',(e)=>{
//   console.log('Hello There', e);
// })
















// import io from 'socket.io-client'
//
// window.socket = io(G.SOCKET_URL,{
//     reconnection: true,
//     reconnectionAttempts:Infinity,
// })
//
// window.socket.io.on('close',()=>{
//     console.log('socket closing')
// })
//
// window.socket.io.on('open',()=>{
//     console.log('socket opening')
//     window.socket.emit('auth',{
//         authToken:JSON.parse(localStorage.getItem('userData')).accessToken
//     })
// })

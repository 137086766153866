import React , { useState } from 'react'
import { Input } from 'reactstrap'


export default function FSwitcher({array=[], type='one', select, title}) {
  const [selected, setSelected] = useState('')

  const onSelect = (i) => {
    console.log(i);
    setSelected(i)
    select(i)
  }

  return(
    <div className='f-switcher'>
      { title && <span>{title}</span> }
      { array.map((i,k)=>(
        <div key={k}>
          <div className='option' onClick={()=> onSelect(i)}>
             <i className={selected==i?'mdi mdi-radiobox-marked':'mdi mdi-radiobox-blank'}/> {i}
           </div>
        </div>
      ))}
    </div>
  )
}
//

import React, { Component } from 'react';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Logo } from './../../assets'
import { G } from './../../core/Global'
import { ItemServices } from './../../services'
import { Spinner, FModal, AddItem, Parent, EditableItem } from './../../components'

let page = 1

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      items:[],
      noItems:false,
      noMore:true,
      selectedItems: (global.order && "selectedItems" in global.order && global.quotationItems.length!=0) ? global.order.selectedItems : global.quotationItems ? global.quotationItems : []
    }
  }

  componentDidMount(){
    page = 1
    this.get(page)
    window.pushItem = (item) =>{
      let selectedItems = this.state.selectedItems
      if (selectedItems.some(e => e.item.name == item.name )) {
        alert('Item already exist.')
      }else {
        let obj = {
          item: item,
          itemPrice: item.price,
          quantity:1,
          parentId: item.parentId
        }
        selectedItems.push(obj)
        console.log('selectedItems',selectedItems);
        this.setState({selectedItems},()=>this.forceUpdate())
      }
    }

    window.popItem = (item) =>{
      let selectedItems = this.state.selectedItems.filter(e=> e.item.id != item.item.id)
      // selectedItems
      console.log(selectedItems);
      this.setState({selectedItems},()=>this.forceUpdate())
    }
  }
  componentWillUnmount(){
    // if (global.idEditItem) return
    global.quotationItems=[]
    // if (global.order)
    //   if (global.order.selectedItems) delete global.order.selectedItems

  }

  get(page){
    this.setState({loading:true})
    let query = `?page=${page}`
    ItemServices.getAll(query, (res)=>{
      this.setState(pS=>({loading:false, items:pS.items.concat(res.data) , noItems:pS.items.concat(res.data).length==0, noMore:res.data.length==0}))
    })
  }

  addItem(){
    console.log(1);
    let data = this.AddItemRef.getData()
    console.log(2, data);
    if (data.type == 'parent') {
      if (!data.name.trim()) {
        alert('Enter parent name')
      }else {
        delete data.type
        data.isParent = 1
        this.add(data)
      }
    }
  }
  add(data){
    this.setState({loading:true})
    ItemServices.add(data, (res)=>{
      this.setState(pS=>({loading:false, addPopup:false, items:pS.items.concat(res.data) }))
    })
  }

  getMore(){
    page+=1
    this.get(page)
  }

  doneEdit(){
    global.order.selectedItems = this.state.selectedItems
    window.goTo(`/orders/${global.order.id}`)
  }

  setCount(item, count){
    let selectedItems = this.state.selectedItems
    let index = selectedItems.findIndex((e => e.item.id == item.item.id))
    selectedItems[index].quantity = count
    this.setState({selectedItems})
  }

  render() {
    return (
      <div className="flex-row main-items">
        <div className="items">
          <div className='space-btw title'>
            <h1>Items</h1>
            {this.state.selectedItems.length != 0?null:(
              <div className='f-btn btn-add' onClick={()=>this.setState({addPopup:true})}>
                <span>Add Parent Item</span>
              </div>
            )}

          </div>


          <div className='list'>
            {this.state.items.map(item=>(
              <Parent
                key={item.id} item={item} 
                noAdd={this.state.selectedItems.length == 0}
              />
            ))}

            {this.state.noMore?(
              <h6 className='no-item'>{this.state.items.length==0?'':'No more.'}</h6>
            ):(
              <h6 className='get-more' onClick={()=>this.getMore()}>Get more </h6>
            )}
          </div>

          {this.state.noItems?(
            <h6 className='no-item'>No items added yet.</h6>
          ):null}

          <FModal
            title='Add item'
            state={this.state.addPopup}
            content={<AddItem ref={ref=>this.AddItemRef=ref}/>}
            onClose={()=>this.setState({addPopup:false})}
            onSubmit={()=>this.addItem()}
            onSubmitText='Add'
          />

          <Spinner loading={this.state.loading} />
        </div>


        {this.state.selectedItems.length == 0?null:(
          <div className='editable-items'>
            <h3>Selected Items</h3>
            {this.state.selectedItems.map(e=>(
              <EditableItem key={e.id} item={e} setCount={(count)=>this.setCount(e,count)}/>
            ))}

            <div className='btn' onClick={()=>this.doneEdit()}>
              <span>DONE</span>
            </div>
          </div>
        )}
        {global.quotationItems? global.quotationItems.length!=0 && this.state.selectedItems.length==0?(
          <div className='editable-items'>
            <h3>Selected Items</h3>
            <span className='note'>You need to add at least one item.</span>
          </div>
        ):null:null}

      </div>
    )
  }
}

export default withRouter(List)

import React, {Component} from 'react'
import { FixerServices } from './../../services'
import { FModal, Spinner } from './../../components'
import MultiSelect from "@kenshooui/react-multi-select"



export default class AvailableFixer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixers:[],
      fixersOptions:[],
      selectedFixer:[],
      page:1
    }
  }
  componentDidMount(){
    this.getFixers()
  }

  getFixers(){
    FixerServices.getAvailable(this.state.page, this.props.order.id, (res)=>{
      if (res.data == 0) {
        this.setState((pS)=>({fixers:pS.fixers, page:'stop'}) )
      }else {
        if(this.state.page != 'stop'){
          let page = this.state.page
          page+=1
          let fixers = res.data
          fixers.map(f=>f.label=f.enName?f.enName:f.arName)
          this.setState((pS)=>({fixers:pS.fixers.concat(fixers), page }),()=>this.getFixers())
        }
      }
    })
  }

  getSelectedFixer(){
   return this.state.selectedFixer
  }

  render(){
    return(
      <div className='block-cener availabel-fixer'>

        <MultiSelect
          items={this.state.fixers}
          selectedItems={this.state.selectedFixer}
          onChange={(e)=>this.setState({selectedFixer:e})}
          withGrouping={false} showSelectedItems={false}
          showSelectAll={false} maxSelectedItems={1}
          responsiveHeight={500}
        />

        {/* <Spinner loading={this.state.loading} /> */}
      </div>
    )
  }
}

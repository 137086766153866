import React, { Component } from 'react';
import { Button, Container } from 'reactstrap'
import { FixerServices } from './../../services'
import { Spinner, FixerSummery } from './../../components'


export default class Single extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
    this.get(global.fixer? global.fixer.id : this.props.match.params.id, true)
  }
  goBack(){
    this.props.history.push({ pathname: `/fixers`, page: this.props.location.page })
  }
  get(id, loading){
    this.setState({loading})
    FixerServices.get(id, res=>{
      global.fixer = res.data
      this.setState({loading:false})
    })
  }

  render() {
    return (
      global.fixer?(
        <div style={{backgroundColor:'#fafafa'}}>
          <div className='flex-row single-order'>

            <div className='back' onClick={()=>this.goBack()}>
              <i className='mdi mdi-chevron-left' style={{fontSize:30}}/>
            </div>

            {this.state.loading?null: <FixerSummery update={()=>this.get(global.fixer.id, false)}/>}

          </div>

          <Spinner loading={this.state.loading}/>
        </div>
      ):null
    )
  }
}

import React , { useState, useEffect } from 'react'
import { Input, Button, Modal } from 'reactstrap'
import { Logo } from './../../assets'
import { G } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'

export default function ContactUS({}) {

  const [ name, setName ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ desc, setDesc ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const send = () => {
    if (!name.trim() || !phone.trim() || !email.trim() || !desc.trim() ) {
      alert('All feilds are reqiured, please enter it')
    }else {
      setLoading(true)
      setTimeout(()=> {
        setLoading(false)
        alert('We successfully received your message. Thank you')
      },2000)
    }
  }

  return(
    <div className='user-manual contact'>
      <img src={Logo} style={{display:'block',margin:'auto',width:150,height:65, marginBottom:20}}/>
      <div className='space-btw'>
        <div>
          <h3>Contact US</h3>
          <div className='under-line'/>
          <h6>Fell free to contact us any time </h6>
          <ul>
            <li>Start with the problem</li>
            <li>Add details that matter</li>
            <li>Don’t use unsuitable words</li>
            <li>Choose address </li>
          </ul>

          <div style={{marginTop:60}}>
            <h5>You can call us on hotline number</h5>
            <div style={{color:G.baseColor, fontSize:40}}>📞 15520</div>
          </div>
        </div>

        <div style={{marginTop:60, width:'50%'}}>
          <Input
            value={name}
            onChange={(e)=>setName(e.target.value)}
            type="text"
            placeholder="Name.."
          />
          <div className='space-btw'>
            <Input
              value={phone}
              onChange={(e)=>setPhone(e.target.value)}
              type="text"
              placeholder="Phone.."
              style={{width:'47%'}}
            />
            <Input
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              type="text"
              placeholder="Email.."
              style={{width:'47%'}}
            />
          </div>

          <Input
            value={desc}
            onChange={(e)=>setDesc(e.target.value)}
            placeholder="Tell us what you need.."
            type="textarea"
            style={{height:120}}
          />
          <Button onClick={send} style={{backgroundColor:G.baseColor,borderWidth:1,borderColor:'white',color:'#fff',marginTop:15, width:'101%', padding:12}}  className="px-4">Send</Button>


        </div>
      </div>

      <Modal isOpen={loading} className='loading'>
        <LoadingOverlay active={loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
      </Modal>
    </div>
  )
}

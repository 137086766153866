import {useReducer} from 'react'

export function validatePhoneNumber(phone){
  if (!phone.startsWith('01')) {
    alert('Phone number must be starts with 01')
    return
  }else if (phone.length !== 11) {
    alert('Phone number must be 11 digits at least')
    return
  }else if (phone.match(/^[0-9]+$/) == null) {
    alert('Enter vaild phone number')
    return
  }else {
    return true
  }
}

export function validateUrl(url){
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(url)
}


export function isNumber(text){
  const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/
  return regex.test(text)
}

export function isInteger(text){
  const regex = /^[0-9]+$/
  return regex.test(text)
}

export function download(filename , blob) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function isArabic(text){
  return text.match(/[\u0600-\u06FF]/)
}



export function connectToSocket () {
  if (localStorage.getItem('AmalUserData')) {
    let payload = {
      authToken:JSON.parse(localStorage.getItem('AmalUserData')).accessToken
    };
    window.socket.emit('auth',payload);
    window.socket.$auth = true;
  }
}



export function isExist(key, array){
  return array.indexOf(key) > -1
}

export function isAllowedToEditQuotation(order){
  return isExist(order.state, ["Pending", "Assigned", "Confirmed", "Started"]) && order.service.id == 2
}

export function isAllowedToCancelOrder(order){
  return isExist(order.state, ["Pending", "Assigned", "Confirmed"])
}

export function canEditQuotation(order){
  return isAllowedToEditQuotation(order) || order.quotation.state != 'converted_to_order'
}

const forceUpdateReducer = (i) => i + 1

export const useForceUpdate = () => {
  const [, forceUpdate] = useReducer(forceUpdateReducer, 0)
  return forceUpdate
}

export const getStateColor = (state) => {
  let colors = {
    Pending:'#9ea7ae',
    Assigned:'#9d4edd',
    Confirmed:'#118ab2',
    Started:'#f4a261',
    Done:'#00a783',
    Completed:'#073b4c',
    Canceled:'#ff3938'
  }
  return colors[state]
}

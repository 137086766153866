import React , { useState, useEffect } from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { Services, OrdersServices } from './../../services'
import regex from './../../core/Regex'


export default function AddNote({ user, order, close }) {

  const { isValidPhoneNumber, isArabic, isMail } = regex

  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)


  const validate = () =>{
    if (!note.trim()) alert('Enter note')
    else {
      const data = {note}
      setLoading(true)
      if (user) {
        Services.addNote(user.id, data , res => {
          setLoading(false)
          res.message=="success" ? close() : alert(res.message)
        })
      }else {
        OrdersServices.addNote(order.id, data , res => {
          setLoading(false)
          res.message=="success" ? close() : alert(res.message)
        })
      }
    }
  }

  return(
    <div className=''>

        <FInput
          placeholder='Note goes here'
          value={note}
          setValue={setNote}
          type="textarea"
          style={{marginBottom:-20}}
        />


      <div className='edit-quotation-btn add-promo' onClick={()=>validate()}>
        <span>Add note</span>
      </div>

      <Spinner loading={loading} />

    </div>
  )
}

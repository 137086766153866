import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class OrdersServices extends Component {

  static getAll(page, query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/orders?page=${page}${query}`).then(callBack)
  }

  static get(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/orders/${id}`).then(callBack)
  }

  static assignFixer(id, data, callBack) {
    Adaptor.patch(`${G.baseURL}admin-panel/orders/${id}/fixer`, data).then(callBack)
  }

  static reviewQuotation(id, callBack) {
    Adaptor.patch(`${G.baseURL}admin-panel/quotations/${id}/review`).then(callBack)
  }

  static editQuotation(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/orders/${id}/quotations`, data).then(callBack)
  }

  static editFixtureQuotation(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/fixture-orders/${id}/change-quotation`, data).then(callBack)
  }

  static getHistory(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/orders/${id}/history`).then(callBack)
  }

  static cancel(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/orders/${id}/cancel`,data).then(callBack)
  }

  static getReport(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/orders/${id}/report`).then(callBack)
  }

  static pay(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/orders/${id}/pay`, data).then(callBack)
  }

  static getReasons(query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/reasons${query}`).then(callBack)
  }

  static reOpen(id, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/orders/${id}/reopen`, {}).then(callBack)
  }

  static getInvoice(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/orders/${id}/invoice`).then(callBack)
  }

  static addNote(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/orders/${id}/addNote`,data).then(callBack)
  }


}

import React, {Component} from 'react'

export default function Tasks({}) {
  return(
    <div className='card rooms'>
      <span className='title' style={{marginBottom:10}}>Items</span>
      <h6 className='title-value'>{global.order.description}</h6>
    </div>
  )
}

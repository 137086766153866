import React from 'react'
import {
  Orders, SingleOrder, Fixers, SingleFixer, Customers, Items, SingleCustomer,
  Promocodes, PromoAdd, Admins, AdminAdd, Compalints, ListRoles, ListReports,
  Forbidden, UserManual
} from './../screens/'

const routes = [
  { path: '/orders', component: Orders, exact:true, hasChild:true },
  { path: '/orders/:id', component: SingleOrder, exact:true },
  { path: '/fixers', component: Fixers, exact:true },
  { path: '/fixers/:id', component: SingleFixer, exact:true },
  { path: '/customers', component: Customers, exact:true },
  { path: '/customers/:id', component: SingleCustomer, exact:true },
  { path: '/items', component: Items, exact:true },
  { path: '/promocodes', component: Promocodes, exact:true },
  { path: '/promocodes/add', component: PromoAdd, exact:true },
  { path: '/admins',  component: Admins, exact:true },
  { path: '/admins/add', component: AdminAdd, exact:true },
  { path: '/complaints', component: Compalints, exact:true },
  { path: '/roles', component: ListRoles, exact:true },
  { path: '/reports', component: ListReports, exact:true },
  { path: '/forbidden', component: Forbidden, exact:true },
  { path: '/user-manual', component: UserManual, exact:true },


]

export default routes

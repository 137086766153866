import React , { useState, useEffect } from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Select from 'react-select'
import { Button } from 'reactstrap'

export default function Filter({ cities, services, setQuery }) {

  const [selectedCities, setSelectedCities] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedDates, setSelectedDates] = useState(null)

  useEffect(()=> setSelectedDates(setDate(new Date(),new Date())) ,[])

  const onApply = (event, picker) => {
    setSelectedDates(setDate(picker.startDate,picker.endDate))
  }
  const onSelect = (ops, act, type) => {
    if (type=='city')
    act.action === 'clear' ? setSelectedCities([]) : setSelectedCities(ops)
    else
    act.action === 'clear' ? setSelectedServices([]) : setSelectedServices(ops)
  }

  const clear = () => {
    setSelectedCities([])
    setSelectedServices([])
    setSelectedDates(setDate(new Date(),new Date()))
    setQuery('')
  }
  const filter = () => {
    let query = ''
    selectedCities.map((e,k)=> query+=`cities[${k}]=${e.value}&`)
    query.includes('city') ? query+='&' : query+=''
    selectedServices.map((e,k)=> query+=`services[${k}]=${e.value}&`)
    if (selectedDates) {
      query+= 'from='+moment(selectedDates.startDate).format('YYYY-MM-DD')+'&'
      query+= 'to='+moment(selectedDates.endDate).format('YYYY-MM-DD')+'&'
    }
    query = '?'+query
    let final = query.slice(0, -1)
    setQuery(final)
  }
  const setDate = (start, end) => {
    let obj = {
      startDate:moment(start).format('YYYY-MM-DD'),
      endDate:moment(end).format('YYYY-MM-DD'),
    }
    return obj
  }
  return(
    <div className='report-filter flex-row center'>



      {selectedDates &&
        <DateRangePicker
          autoApply={false}
          autoUpdateInput={false}
          initialSettings={{
            startDate: moment(new Date()).toDate(),
            endDate: moment(new Date()).toDate() ,
            locale:{format:'YYYY-MM-DD'},
            autoApply:true
          }}
          onApply={onApply}>
          <div className="date-input flex-row">
            <i className='mdi mdi-calender'/>
            <span>
              {moment(selectedDates.startDate).format('YYYY-MM-DD') +' - ' + moment(selectedDates.endDate).format('YYYY-MM-DD')}
            </span>
          </div>
        </DateRangePicker>
      }


      {services &&
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={services.map(e=>({value:e.id,label:e.enName}))}
          style={{width: '-webkit-fill-available'}}
          placeholder='Service..'
          value={selectedServices}
          onChange={(ops, act)=> onSelect(ops, act, 'service')}
        />
      }

      <Select
        closeMenuOnSelect={false}
        isMulti
        options={cities.map(e=>({value:e.id,label:e.enName}))}
        style={{width: '-webkit-fill-available'}}
        placeholder='City..'
        value={selectedCities}
        onChange={(ops, act)=> onSelect(ops, act, 'city')}
      />

      <Button className='submit-btn' onClick={filter}>Filter</Button>

      <span onClick={clear} className='clear'>Clear</span>


    </div>
  )
}

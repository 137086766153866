import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { getMessaging, onMessage } from "firebase/messaging"
import NetworkDetector from './core/NetworkDetector'
import { style, mediaQuery } from './assets'
import { Content } from './components'
import { Login, HowToUse, ContactUS } from './screens'
import './core/Interceptor'
import './core/SocketHandler'
import "@kenshooui/react-multi-select/dist/style.css"
import 'bootstrap-daterangepicker/daterangepicker.css'
import "react-datepicker/dist/react-datepicker.css"

class App extends Component {
  componentDidMount(){
    global.quotationItems = []
    global.quotationData = {}

    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      console.log('onMessage', payload)
      if (payload.data.type == 'order') window.pushNewOrder && window.pushNewOrder(payload)
      if (payload.data.type == 'orderUpdate') window.orderUpdate && window.orderUpdate(payload)
      if (payload.data.type == 'complaint') window.newComplaint && window.newComplaint(payload)
      if (payload.data.type == 'complaintMessage') window.newMsg && window.newMsg(payload)
    });
  }
  render() {
    return (
      <BrowserRouter>
        <React.Suspense>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/how-to-use" name="How to use" render={props => <HowToUse {...props}/>} />
            <Route exact path="/contact-us" name="Contact us" render={props => <ContactUS {...props}/>} />

            <Route path="/" name="Home" render={props => <Content {...props}/>} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default NetworkDetector(App)

export { default as Services } from './main/Services'
export { default as AuthServices } from './auth/AuthServices'
export { default as OrdersServices } from './order/OrdersServices'
export { default as FixerServices } from './fixer/FixerServices'
export { default as CustomerServices } from './customer/CustomerServices'
export { default as ItemServices } from './item/ItemServices'
export { default as PromoServices } from './promo/PromoServices'
export { default as AdminServices } from './admin/AdminServices'
export { default as CompalintsServices } from './compalints/CompalintsServices'
export { default as RolesServices } from './roles/RolesServices'

import React, {Component} from 'react'
import { G } from './../../../core/Global'

export default class Summery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: []
    }
  }

  componentDidMount(){
    let items = ("selectedItems" in global.order && this.props.noDivider) ? global.order.selectedItems : global.order.quotation.items
    console.log(items);
    let merged = items.map(e=>{
      e.parentId = e.item.parentId
      return e
    }).reduce((r, { parentId ,...rest}) => {
      const key = `${parentId}`
      r[key] = r[key] || { key, result: [] }
      r[key]["result"].push(rest)
      return r
    }, {})

    items = Object.values(merged)
    this.setState({items})
  }

  render(){
    return(
      <div >
        {!this.props.noDivider?
          <span className='child'>Items</span>
          :<p>Items</p>}

        {this.state.items.map(e=>(
          <div key={e.key}>
            {e.result[0].item.parent && <span className='parent'>{e.result[0].item.parent.name}</span> }
            {e.result.map(i=>(
              <div key={i.item.id} className='space-btw' style={{paddingLeft:10}}>
                <span className='child'>{i.quantity} <i className='mdi mdi-close' /> {i.item.name}</span>
                <span className='child'>{(parseFloat(i.itemPrice)*i.quantity).toFixed(2)} EGP</span>
              </div>
            ))}
          </div>
        ))}
        {this.props.noDivider?null:<div className='divider'/>}
      </div>
    )
  }
}

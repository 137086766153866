import React , { useState, useEffect } from 'react'
import { FInput, FSwitcher, FMultiSelector, Spinner } from './../../components'
import { Input } from 'reactstrap'
import DatePicker from "react-datepicker"
import { PromoServices, Services } from './../../services'
import moment from 'moment'

let citiesRes = []

export default function Add({ goBack }) {

  const [name, setName] = useState('')
  const [amount, setAmount] = useState('')
  const [uses, setUses] = useState('')
  const [maxUses, setMaxUse] = useState('')
  const [type, setType] = useState('')
  const [cities, setCities] = useState([])
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(()=> getCities(), [])

  const getCities = () => {
    Services.getCities(res=> { citiesRes = res.data.cities })
  }

  const validate = () =>{

    if (!name.trim()) alert('Enter name')
    else if (!amount.trim()) alert('Enter amount')
    else if (!type.trim()) alert('Select type')
    else if (!uses.trim()) alert('Enter number of uses')
    else if (type.toLowerCase() == 'percentage' && !maxUses.trim()) alert('Enter max number of uses')
    // else if (parseFloat(maxUses) > parseFloat(amount) ) alert(`Max amount of promo shouldn't be greater than the amount of promo amount`)
    else if (dateFrom=='') alert('Enter start date')
    else if (dateTo=='') alert('Enter end date')
    else if (cities.length == 0) alert('Select cities')
    else {
      const data = {
        code: name,
        amount: amount,
        type: type.toLowerCase(),
        validFrom: moment(dateFrom).format('YYYY-MM-DD'),
        validTo: moment(dateTo).format('YYYY-MM-DD') ,
        numberOfUsesPerUser: uses,
        cities: getCitiesIds(cities, citiesRes),
        // maxAmountOfDiscount:maxUses
      }
      if (type.toLowerCase() == 'percentage') data.maxAmountOfDiscount = maxUses
      console.log('data',data);
      setLoading(true)
      PromoServices.add(data , res=> {
        console.log('res',res);
        setLoading(false)
        goBack()
      })
    }
  }

  const getCitiesIds = (select, res) =>{
    let arr = []
    return select.map(s => arr.push(res.find(r => s == r.enName).id) )
  }

  return(
    <div className='card'>
      <span className='main-title'>Promocodes</span>
      <div style={{width:'45%'}}>

        <FInput
          title='Name'
          placeholder='Name'
          value={name}
          setValue={setName}
        />

        <FInput
          title='Amount'
          placeholder='Amount'
          value={amount}
          setValue={setAmount}
          numbersOnly
        />

        <FSwitcher
          title='Promocode type'
          array={['Fixed','Percentage']}
          select={setType}
        />

        <FInput
          title='No of uses'
          placeholder='No of uses'
          value={uses}
          setValue={setUses}
          numbersOnly
        />

        {type.toLowerCase() == 'percentage' &&
        <FInput
          title='Max amount of discount'
          placeholder='Max amount of discount'
          value={maxUses}
          setValue={setMaxUse}
          numbersOnly
        />}

      </div>

      <div className='space-btw'>
        <div style={{width:'45%'}} className='f-input'>
          <span>Applied date from</span>
          <DatePicker
            selected={dateFrom}
            onChange={setDateFrom}
            dateFormat="dd-MM-yyyy"
            placeholderText='Start date'
            minDate={new Date()}
          />
        </div>
        <div style={{width:'45%'}} className='f-input'>
          <span>Applied date to</span>
          <DatePicker
            selected={dateTo}
            minDate={dateFrom}
            disabled={dateFrom==''}
            onChange={setDateTo}
            dateFormat="dd-MM-yyyy"
            placeholderText='End date'
          />
        </div>
      </div>

      <FMultiSelector
        title='Cities'
        array={citiesRes.map(e=> e.enName)}
        select={setCities}
      />

      <div className='edit-quotation-btn add-promo' onClick={()=>validate()}>
        <span>Done</span>
      </div>

      <Spinner loading={loading} />

    </div>
  )
}

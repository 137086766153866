import React, {Component} from 'react'
import {Modal} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay';
import GoogleMapReact from 'google-map-react'

export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render(){
    return(
      <div className='state'>
        <div className='space-btw item'>
          <span>Status</span>
          <span>{global.order.state}</span>
        </div>
        <div className='space-btw item'>
          <span>Service</span>
          <span>{global.order.service.enName}</span>
        </div>
        <div className='space-btw item'>
          <span>Payment</span>
          <span>{global.order.paymentMethod?global.order.paymentMethod.enName:'Cash'}</span>
        </div>
        {global.order.promoCode &&
          <div className='space-btw item'>
            <span>Promo</span>
            <span>{global.order.promoCode.code}</span>
          </div>
        }
      </div>

    )
  }
}

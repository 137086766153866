import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class CustomerServices extends Component {

  static getAll(page=1, query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/customers?page=${page}${query}`).then(callBack)
  }
  static get(id, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/customers/${id}`).then(callBack)
  }

}

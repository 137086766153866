import React , { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import { ForbiddenImg } from './../../assets'

export default function Manual({}) {

  return(
    <div className='user-manual'>
      <h1>User Manual For [Admin panel]</h1>


      <h3>Orders page</h3>
      <div className='under-line'/>
      <h6>- Admin can see all orders with order </h6>
      <ul>
        <li>id</li>
        <li>Fixer name </li>
        <li>Customer name </li>
        <li>Customer phone </li>
        <li>Order status </li>
        <li>Order map</li>
        <li>Order requested date and time </li>
        <li>And can click on order card to open single order details page </li>
      </ul>

      <h6>- Orders Types: </h6>
      <ol>
        <li>Assay order </li>
        <li>Fixture order </li>
        <li>Maintenance order </li>
        <li>Emergency order </li>
      </ol>

      <h6>- Functionalities: </h6>
      <ul>
        <li>Admin can filter with [City - Area -Service group - State - Requested Date ]</li>
        <li>Admin can search with [order id , Customer name , Customer phone ,Fixer name ,Fixer phone]</li>
        <li>Admin can Assign Fixer to order in these statuses [pending -assigned -Confirmed]</li>
        <li>Admin can Cancel orders [pending - Assigned - Confirmed]</li>
        <li>Admin can view order  logs </li>
        <li>Admin can Reopen Completed order [after reopen order Status changed to Done]</li>
        <li>Admin can Reopen Canceled order [after reopen status Change to status previous canceled status]</li>
        <li>Admin can confirm order payment</li>
      </ul>




      <h3>Reports page</h3>
      <div className='under-line'/>
      <ul>
        <li>Admin can see received orders per day report  [it present the received orders per day ]</li>
        <li>Admin can filter with [ date (from - to) , City , Service group] </li>
        <li>Admin can see Done orders per day report  [it present number of done order which their requested date in the represented date ]</li>
        <li>Admin can filter with [ date (from - to) , City , Service group] </li>
        <li>Admin can see Registered customers per day report [it represent the number of registered customer per day]</li>
        <li>Admin can filter with [ date (from - to) , City ]</li>
      </ul>



      <h3>Fixers page</h3>
      <div className='under-line'/>
        <ul>
          <li>Admin can filter with [ City - Status -is-BLocked ]</li>
          <li>Admin can search with [Fixer id , Fixer  name , Fixer phone]</li>
          <li>Admin can see all fixers with their</li>
          <ol>
            <li>ID </li>
            <li>FIXERS NAME </li>
            <li>FIXERS PHONE </li>
            <li>ORDERS</li>
            <li> RATE </li>
            <li>STATUS </li>
            <li> IS-BLOCKED</li>
            <li> CITY </li>
          </ol>
        </ul>



      <h6>- Fixers Status are [New - Active - inActive]: </h6>
      <ul>
        <li>New status : Fixer who are registered but still not be able to use the software and admin can’t assigned them to order</li>
        <li>Active : Fixer who are Activated on system and ready to be  Assigned to orders</li>
        <li>in-Active : Fixers who are Not Activated on the system Which mean that Admin can’t assign them to orders</li>
      </ul>

      <h6>- Functionalities: </h6>
      <ul>
        <li>Admin can change new Fixer status to Activated</li>
        <li>Admin can change Active fixer to inactive</li>
        <li>Admin can change in-active fixer to Active</li>
        <li>Fixer is Blocked / unblocked</li>
        <li>Admin Can block / unblock fixer</li>
        <li>System Block fixers in Collection Cycle</li>
        <li>Admin can add note on Fixer</li>
        <li>Admin can see all notes from single fixer page</li>
        <li>Admin can send sms to each fixer</li>
        <li>Admin can send push notification to each Fixer</li>
        <li>Admin can see Fixer Balance</li>
      </ul>





      <h3>Customers page</h3>
      <div className='under-line'/>
      <h6>- Admin can see all fixers with their </h6>
      <ul>
        <li>ID</li>
        <li>Customer Name </li>
        <li>Customer phone </li>
        <li>Join date </li>
        <li>Orders number for each customer </li>
        <li>Is Blocked</li>
      </ul>

      <h6>- Functionalities: </h6>
      <ul>
        <li>Admin can search with [Fixer id , Fixer  name , Fixer phone]</li>
        <li>Admin can Block / unblock Customer</li>
        <li>Admin can add note on Customer </li>
        <li>Admin can see all notes on Customer</li>
        <li>Admin can send Sms to each customer</li>
        <li>Admin can send Push notification to each Customer</li>
        <li>Admin can See Customers Balance</li>
      </ul>


      <h3>Complaints page</h3>
      <div className='under-line'/>
        <ul>
          <li>Admin can see all Customers  Complaints</li>
          <li>Complaint order id</li>
          <li>Complaint id </li>
        </ul>

      <h6>- Complaints statuses [New - inprogress - Closed]: </h6>
      <ul>
        <li>New : all received complaints from customers without any reply from admin.</li>
        <li>Inprogress : once admin reply on the complain , complain Status Changed to Inprogress</li>
        <li>CLosed: when admin changes Complaint status to Closed then Status is Closed and Admin can’t Reply on this Complaint neither customer.</li>
      </ul>

      <h6>- Functionalities: </h6>
      <ul>
        <li>Admin can change Complaint status: </li>
          <ul>
            <li>From New To inprogress or Closed </li>
            <li>From inprogress to Closed </li>
            <li>From Closed to inprogress</li>
          </ul>
        <li>Admin can Filter in complaints with [City , status ]</li>
        <li>Admin can search in complaints with [Fixer name , fixer phone , Customer name , customer phone ]</li>
        <li>Admin can reply to Customers complaints</li>
      </ul>


      <h3>PromoCode page</h3>
      <div className='under-line'/>
      <h6>- Admin can see all promocodes with </h6>
      <ul>
        <li>ID</li>
        <li>Code name</li>
        <li>Amount</li>
        <li>Uses</li>
        <li>Max uses</li>
        <li>Type</li>
        <li>Applied from</li>
        <li>Applied to</li>
        <li>Cities</li>
        <li>Promo Code types :</li>
        <ul>
          <li>Fixed Type : deducted fixed amount from the order price</li>
          <li>Percentage type : deduct percent from the order price with amount deduction amount</li>
        </ul>
        <li>Number of uses : max number of uses per each user </li>
      </ul>


      <h3>items page</h3>
      <div className='under-line'/>
      <ul>
        <li>Admin can see system items</li>
        <li>Admin can add Items</li>
      </ul>


      <h3>Admins page</h3>
      <div className='under-line'/>
      <ul>
        <li>Admin can see  all admins</li>
        <li>Admin can add new admin [admin’s password will be sent to new registered admin ]</li>
        <li>Admin can search by [name , phone ]</li>
        <li>Admin can add roles to each admin</li>
      </ul>


      <h3>Roles page</h3>
      <div className='under-line'/>
      <ul >
        <li>Admin can see  all Roles</li>
        <li>Admin can add New Roles with permissions</li>
        <ul>
          <li>For ex ( marketing team has the access for promocodes page only )</li>
          <li>Admin will make Role with name Marketing_team ( List promoCodes , Add promo codes )</li>
          <li>And from admins page add admin marketing role to marketing admins</li>
        </ul>
      </ul>

      <div style={{height:70}}/>

    </div>
  )
}

import React, { Component } from 'react';
import { Modal, } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import noInternet from './../assets/images/no-internet.png'

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      loading:false
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch('//google.com', {
              mode: 'no-cors',
              })
            .then(() => {
              this.setState({ isDisconnected: false, loading:false }, () => {
                return clearInterval(webPing)
              });
            }).catch(() => this.setState({ isDisconnected: true, loading:true }) )
          }, 2000);
        return;
      }

      return this.setState({ isDisconnected: true, loading:true });
    }

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
          <ComposedComponent {...this.props} />
          <Modal isOpen={this.state.loading} className='loading noInternetModal'>
            <div className='internet'>
              <img src={noInternet}/>
              <h2>Internet connection is lost</h2>
            </div>
          </Modal>
        </div>
      );
    }
  }

  return NetworkDetector;
}

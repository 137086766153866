import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { UncontrolledTooltip } from 'reactstrap'
import { OrdersServices } from './../../services'
import { MapMarker, FModal, AvailableFixer, Spinner, Parent } from './../../components'
import { ItemServices } from './../../services'
import Counter from './Counter'

export default class Editable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count:this.props.item.quantity
    }
  }

  componentDidMount(){

  }

  updateCount(count){
    this.setState({count})
    this.props.setCount(count)
  }

  render(){
    return(
      <div className='space-btw editable-item'>
        <span onClick={()=>window.popItem(this.props.item)}><i className='mdi mdi-check-circle'/>  {this.props.item.item.name}</span>
        <div>
          <span>{(parseFloat(this.props.item.itemPrice)*this.state.count).toFixed(2)} <span style={{fontSize:10, marginLeft:3}}>EGP</span></span>
          <Counter count={this.state.count} setCount={(count)=>this.updateCount(count)} />
        </div>
      </div>
    )
  }
}

export class G {

  // static baseURL = `https://test.fixawy.net/api/v1/`
  // static baseURL = `https://alamal.fixawy.net/api/v1/`
  static baseURL = `https://api.sabbak.net/api/v1/`

  static url = `http://localhost:3000/`
  // static url = `https://alamal-panel.fixawy.net/`

  static MAP_API_KEY = 'AIzaSyB7gE3tz63Yo0Sj2HiVYWeObdGDsr4tK5Q'

  static APP_KEY = 'fixawy12345'
  static APP_CLUSTER = 'mt1'



  static version = `1.0.0`

  static baseColor = '#1a3b73'
  static baseColor2 = '#52c3c2'
  static borderColor = '#CBF4F2'

  static groupBy(arr, key){
    const merged = arr.reduce((r, { key,...rest}) => {
      // const key = `${key}`
      r[key] = r[key] || { key, result: [] }
      r[key]["result"].push(rest)
      return r
    }, {})
    return Object.values(merged)
 }

 static complainStatus = ['new', 'in-progress', 'closed']


}

import React, { Component } from 'react'
import { Nav, NavItem, Container, UncontrolledTooltip } from 'reactstrap'
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import {Logo} from './../../assets'
import {G} from './../../core/Global'
import { FModal, Spinner } from './../../components'
import { Services } from './../../services'


class DefaultHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      admin: {}
    }
  }

  componentDidMount(){
    Services.getUser( res => this.setState({admin: res.data}))
  }

  logout(){
    this.setState({loading:true})
    Services.logout(res=>{
      this.setState({loading:false})
      if (res.message == 'success') {
        localStorage.removeItem('AmalLogged')
        localStorage.removeItem('AmalUserData')

        this.props.history.push('/login')
      }
    })
  }

  render() {
    return (
      <Container style={{marginLeft:0, marginRight:0, maxWidth:window.screen.availWidth }}>

        <React.Fragment>

          <AppSidebarToggler className="d-lg-none" display="md" mobile />

          <AppNavbarBrand
            className='logo'
            style={{width: 188, marginLeft: -3}}
            full={{ src: Logo, width: 110, height: 50, alt: 'Fixawy Logo' }}
            minimized={{ src: Logo, width: 40, height: 45, alt: 'Fixawy Logo' }}
          />


        </React.Fragment>

        <div className="d-md-down-none admin-name">
          <span >{this.state.admin.name} - {this.state.admin.phone}</span>
          <i className='mdi mdi-logout' onClick={()=>this.setState({logoutPopup:true})}/>
        </div>

        <FModal
          title='Logout'
          state={this.state.logoutPopup}
          content={<h6 style={{textAlign:'center'}}>Are you sure you want logout ?</h6>}
          onClose={()=>this.setState({logoutPopup:false})}
          onSubmit={()=>this.logout()}
          width='50%'
        />
        <Spinner loading={this.state.loading} />
      </Container>
    );
  }
}

export default withRouter(DefaultHeader)

import React, {Component} from 'react'
import moment from 'moment'

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <div className='card' style={{padding:'0% 32% 2% 7%'}}>
        <div className='space-btw cell-row'>
          <div>
            <span className='title'>Order number</span>
            <span className='title-value'>#{global.order.id}</span>
          </div>
          <div>
            <span className='title'>Creation date</span>
            <span className='title-value'>{moment(global.order.createdAt).format('YYYY/MM/DD')}</span>
          </div>
        </div>
        <div className='space-btw cell-row' style={{paddingRight:'5%'}}>
          <div>
            <span className='title'>Requested date</span>
            <span className='title-value'>{moment(global.order.requestedDate).format('YYYY/MM/DD')}</span>
          </div>
          <div>
            <span className='title'>Period</span>
            <span className='title-value'>{global.order.period.enName}</span>
          </div>
        </div>
      </div>
    )
  }
}

import React, {Component} from 'react'
import { FixerServices } from './../../../services'
import { Spinner, FModal, UpdateFixerStatus } from './../../../components'

export default class Summery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocked: global.fixer.isBlocked,
      isActive: global.fixer.state == 'active'
    }
  }

  block(){
    this.setState({loading:true})
    FixerServices.block(global.fixer.id, res => {
      this.setState({loading:false, isBlocked:!this.state.isBlocked })
    })
  }

  checkAction(state){
    if (state == 'active') this.set(global.fixer.id, {state})
    else this.set(global.fixer.id, {state})
  }

  set(id, data){
    this.setState({loading:true})
    FixerServices.setSatatus(id, data, res => {
      this.setState({loading:false,updatePopup:false, isActive:!this.state.isActive},()=>{
        global.fixer.state = this.state.isActive ? 'active' : 'in-active'
        this.forceUpdate()
      })

    })
  }

  render(){
    return(
      <div className='state'>
        <div>
          <div>
            <div className='space-btw'>
              <span className='title'>Status</span>
              <span className='title verified'>{global.fixer.state}</span>
            </div>
            {global.fixer.state == 'new'?(
              <div className='space-btw toggle'>
                <div onClick={()=>this.setState({updatePopup:true})}>
                  <span className='title new'> Verifiy new fixer</span>
                </div>
              </div>
            ):(
              <div className='space-btw toggle'>
                <div className={!this.state.isActive?'flex-row':'flex-row active'} onClick={()=>!this.state.isActive?this.checkAction('active'):null}>
                  <i className={!this.state.isActive?'mdi mdi-circle-outline':'mdi mdi-circle'}/>
                  <span className='title'> Active</span>
                </div>
                <div className={this.state.isActive?'flex-row':'flex-row active'} onClick={()=>this.state.isActive?this.checkAction('in-active'):null}>
                  <i className={this.state.isActive?'mdi mdi-circle-outline':'mdi mdi-circle'}/>
                  <span className='title'>Inactive</span>
                </div>
              </div>
            )}
          </div>


          <div>
            <div className='space-btw '>
              <span className='title'>Is blocked</span>
              {global.fixer.block&&
                <span className='title'>{global.fixer.block.reason}</span>
              }
            </div>
            <div className='space-btw toggle'>
              <div className={this.state.isBlocked?'flex-row':'flex-row active'} onClick={()=>this.state.isBlocked?this.block():null}>
                <i className={this.state.isBlocked?'mdi mdi-circle-outline':'mdi mdi-circle'}/>
                <span className='title'> Un blocked</span>
              </div>
              <div className={!this.state.isBlocked?'flex-row':'flex-row active'} onClick={()=>!this.state.isBlocked?this.block():null}>
                <i className={!this.state.isBlocked?'mdi mdi-circle-outline':'mdi mdi-circle'}/>
                <span className='title'> Blocked</span>
              </div>
            </div>
          </div>


          <div className='space-btw'>
            <span className='title'>Verification</span>
            <span className={global.fixer.isPhoneVerified?'title verified':'title'}>{global.fixer.isPhoneVerified? 'Verified' : 'Not verified'}</span>
          </div>
        </div>


        <FModal
          title='Update fixer status'
          state={this.state.updatePopup}
          content={<UpdateFixerStatus
            ref={(ref)=> this.updateFixerRef = ref}
            fixer={global.fixer}
            set={(fixer, data)=> this.setState({isActive:false},()=>this.set(fixer.id, data))}
          />}
          onClose={()=>this.setState({updatePopup:false})}
          onSubmit={()=>this.updateFixerRef.validation()}
          onSubmitText='Done'
        />

        <Spinner loading={this.state.loading}/>
      </div>

    )
  }
}

// const MapMarker = () => <div><i className='mdi mdi-map-marker' style={{fontSize:30, color:'#1a3b73', position:'absolute'}} /></div>
import React, { Component } from 'react'

export default class MapMarker extends Component {
  render(){
    return(
    <div><i className='mdi mdi-map-marker' style={{fontSize:30, color:'#1a3b73', position:'absolute'}} /></div>
    )
  }
}

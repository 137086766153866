import React, {Component} from 'react'
import {Fixer} from './../../../assets'
import moment from 'moment'
import StarRatings from 'react-star-ratings'

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <div className='card'>

        <img src={Fixer} style={{width:120, height:120, alignSelf:'center', margin:'20px 0px 40px 0px'}}/>

        <div className='info' >
          <div>

            <div>
              <span className='title'>Fixer name</span>
              <span className='title-value'>{global.fixer.arName}</span>
            </div>

            <div>
              <span className='title'>City</span>
              <span className='title-value'>{global.fixer.city.enName}</span>
            </div>

            <div>
              <span className='title'>Orders</span>
              <span className='title-value'>{global.fixer.ordersCount}</span>
            </div>

            <div>
              <span className='title'>Verification status</span>
              <span className='title-value'>{global.fixer.isPhoneVerified?'Verified':'Not verified'}</span>
            </div>

          </div>

          <div >

            <div>
              <span className='title'>Phone</span>
              <span className='title-value'>{global.fixer.phone}</span>
            </div>

            <div>
              <span className='title'>Join date</span>
              <span className='title-value'>{moment(global.fixer.joinDate).format('YYYY/MM/DD')}</span>
            </div>

            <div>
              <span className='title'>Rate</span>
              {/* <span className='title-value'>{global.fixer.averageTotalRate}</span> */}
              <StarRatings
                rating={global.fixer.averageTotalRate}
                starRatedColor="#000"
                starDimension='20px'
                starSpacing='3px'
                // changeRating={this.changeRating}
                numberOfStars={5}
                name='rating'
              />
            </div>

          </div>

        </div>

      </div>
    )
  }
}

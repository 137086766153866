import React, { Component } from 'react';
import { G } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class AdminServices extends Component {

  static get(page=1, query, callBack) {
    Adaptor.get(`${G.baseURL}admin-panel/admins?page=${page}${query}`).then(callBack)
  }

  static add(data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/admins`, data).then(callBack)
  }

  static assignRole(id, data, callBack) {
    Adaptor.post(`${G.baseURL}admin-panel/admins/${id}/roles`, data).then(callBack)
  }

}

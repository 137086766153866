import React, {Component} from 'react'
import { FModal, AddNote } from './../../../components'
import { isAllowedToCancelOrder } from './../../../core/Helper'
import Logs from './Logs'
import Cancel from './Cancel'

export default class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return(
      <div className='space-btw actions'>
        {/* <div>
          <span>Compaints</span>
        </div> */}
        <div onClick={()=>this.setState({logsPopup:true})}>
          <span>Logs</span>
        </div>

        <div onClick={()=>this.setState({notePopup:true})}>
          <span>Add Note</span>
        </div>

        {isAllowedToCancelOrder(global.order) &&
          <div className='btn-cancel' onClick={()=> this.setState({cancelPopup:true})}>
            <span>Cancel</span>
          </div>
        }


        <FModal
          title='Order logs'
          state={this.state.logsPopup}
          content={<Logs />}
          onClose={()=>this.setState({logsPopup:false})}
          noSubmitBtn
          width={'70%'}
        />

        <FModal
          title={`Cancel Order #${global.order.id}`}
          state={this.state.cancelPopup}
          content={<Cancel update={()=>this.props.update()}/>}
          onClose={()=>this.setState({cancelPopup:false})}
          noSubmitBtn
        />

        <FModal
          title={`Add note`}
          state={this.state.notePopup}
          content={<AddNote order={global.order} close={()=>this.setState({addNotePopup:false,},()=>this.props.update() )}/>}
          onClose={()=>this.setState({notePopup:false})}
          noSubmitBtn
        />

      </div>
    )
  }
}

import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap'
import { OrdersServices } from './../../services'
import { MapMarker, FModal, AvailableFixer, Spinner } from './../../components'
import moment from 'moment'
import { isNumber } from './../../core/Helper'

export default class AddChild extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu:[
        {id:1, key:'صنف'},
        {id:2, key:'مقاس'},
        {id:3, key:'ضغط'},
        {id:4, key:'سمك'},
        {id:5, key:'قطر خارجي'},
      ],
      first:'',
      firstValue:'',
      other:'',
      childItems:[],
      data:[],
      price:''
    }
  }

  selectType(item){
    if (this.state.childItems.length == 0) {
      this.setState({first:item})
    }else {
      this.setState({['other'+(this.state.childItems.length-1).toString()]: item})
    }
  }

  pushItem(key, value){
    if (!value) {
      alert('Enter value')
    }else {
      let childItems = this.state.childItems
      let data = this.state.data
      let menu = []
      if (this.state.childItems.length == 0) {
        menu = this.state.menu.filter(e=> e.id != key.id)
        data.push({key:key.key, value:value})
      }else {
        menu =  this.state.menu.filter(e=> e.id != this.state.['other'+(childItems.length-1).toString()].id)
        data.push({key:this.state.['other'+(childItems.length-1).toString()].key, value:this.state.['otherValue'+(childItems.length-1).toString()]})
      }
      childItems.push({key:'', value:''})

      console.log(data);
      this.setState({ ['other'+(childItems.length-1).toString()]:'' , childItems, menu, data })
    }

  }

  getData(){
    let state = this.state
    let details = []
    if (state.price == '') {
       alert('Enter price')
      return 'stop'
    }else if (state.first == '') {
       alert('Add at least on slot')
      return 'stop'
    }else {
      details.push({key:state.first.key, value:state.firstValue})
      for (var i = 0; i < 5; i++) {
        if (state.['other'+i.toString()]) {
          details.push({key:state.['other'+i.toString()].key, value:state.['otherValue'+i.toString()]})
        }
      }
      console.log('details', details);
      let data = {
        price: state.price,
        details
      }
      return data
    }
  }

  onChangePrice(e){
    if (isNumber(e.target.value) || e.target.value == 0) {
      this.setState({price : e.target.value})
    }else {
      alert('Please, enter valid numbers')
    }
  }


  render(){
    return(
      <div className='add-child'>

        <p style={{fontSize:18, marginBottom:0}}>Price</p>
        <Input
          value={this.state.price}
          onChange={(e)=>this.onChangePrice(e)}
          type="text"
          placeholder='Enter price'  pattern="[0-9]*"
        />

        <p style={{fontSize:18}}>Details</p>
        <div className='space-btw'>
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle tag="a" className="nav-link" caret>
              {this.state.first == ''?'Type': this.state.first.key}
            </DropdownToggle>
            <DropdownMenu>
              {this.state.menu.map(i=>(
                <DropdownItem key={i.id} onClick={()=>this.selectType(i)}>{i.key}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <Input
            disabled={this.state.first==''}
            value={this.state.firstValue}
            onChange={(e)=>this.setState({firstValue:e.target.value})}
            type="text"
            placeholder={this.state.first==''?'Select type first':'Enter value'}
          />

          {this.state.data.length < 4?(
            <i className='mdi mdi-plus-circle-outline add' onClick={()=>this.pushItem(this.state.first, this.state.firstValue)}/>
          ):null}

        </div>

        {this.state.childItems.map((i,k)=>(
          <div className='flex-row' key={k}>
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle tag="a" className="nav-link" caret>
                {this.state.['other'+k] == ''?'Type': this.state.['other'+k].key}
              </DropdownToggle>
              <DropdownMenu>
                {this.state.menu.map(i=>(
                  <DropdownItem key={i.id} onClick={()=>this.selectType(i)}>{i.key}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>

            <Input
              disabled={this.state.['other'+k]==''}
              value={this.state.['otherValue'+k]}
              onChange={(e)=>this.setState({['otherValue'+k]:e.target.value})}
              type="text" style={{width:'80%'}}
              placeholder={this.state.['other'+k]==''?'Select type first':'Enter value'}
            />

            {/* <i className='mdi mdi-plus-circle-outline add' onClick={()=>this.pushItem()}/> */}
          </div>
        ))}

      </div>
    )
  }
}

import React, {  useState, useEffect } from 'react'
import { ReportsFilter, Chart } from './../../components'
import { Services } from './../../services'

export default function RecievedOrders({cities, services}) {

  const [data, setData] = useState(null)
  const [query, setQuery] = useState('')

  useEffect(()=> getReport(query) ,[query])

  const getReport = (query) => {
    Services.getReport(query?query:'', 'orders', res => {
      let data = {
        labels:res.data.map(e=> e.createdAt),
        datasets: [
          {
            label: '# of Received Orders',
            data:res.data.map(e=> e.count),
            backgroundColor:'#52c3c2',
            borderWidth: 1,
          },
        ],
      }
      setData(data)
    })
  }

  return(
    <div className="single">

      <div className='space-btw'>
        <span className='report-title'>Received orders per day</span>

        <ReportsFilter cities={cities} services={services} setQuery={setQuery}/>

      </div>

      {data && <Chart data={data}/> }

    </div>
  )
}

import React, { useState, useEffect } from 'react'
import RecievedOrders from './RecievedOrders'
import DoneOrders from './DoneOrders'
import Customers from './Customers'
import { Services } from './../../services'

export default function List() {

  const [cities, setCities] = useState(null)
  const [services, setServices] = useState(null)

  useEffect(()=> { getCities(); getServices() } ,[])

  const getCities = () => {
    Services.getCities(res=> setCities(res.data.cities))
  }

  const getServices = () => {
    Services.getServices(res=> setServices(res.data))
  }

  return(
    <div className="customers reports">

      <h1>Reports</h1>

      {cities && services && <RecievedOrders cities={cities} services={services}/>}

      {cities && services && <DoneOrders cities={cities} services={services}/>}

      {cities && <Customers cities={cities} services={false}/>}

    </div>
  )
}
